import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificationType, SignalType } from 'angular-to-phaser';
import { BehaviorSubject, Observable, catchError, map, merge, retry, shareReplay, switchMap, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Channel, Message, MessageResponse, NotificationSignal, Signal } from '../dto';
import { ApplicationService } from './application.service';
import { BaseMessagingService } from './base-messaging.service';
import { NotificationService } from './notification.service';

@Injectable({
    providedIn: 'root'
})
export class MessageboardService extends BaseMessagingService {

    constructor(coreAppService: ApplicationService, http: HttpClient, notificationService: NotificationService) {
        super(coreAppService, http, notificationService);

        this.notificationService.persistentMessageboardNotifications.pipe(
            switchMap(notifications => {
                let channelGroupedNotifications = notifications
                    .filter(n => n.NotificationType == NotificationType.MessageBoard)
                    .map(n => {
                        (<Message>n.Entity).targetingNotification = n;
                        return <Message>n.Entity;
                    })
                    .reduce(
                        (entryMap, e) => entryMap.set(e.ChannelId, [...entryMap.get(e.ChannelId) || [], e]), //this is a very slick groupby channel id
                        new Map<number, Message[]>()
                    );
                let keyset = [...channelGroupedNotifications.keys()];
                return merge(...keyset.map(k => this.insertMessagesToChannel(channelGroupedNotifications.get(k), true)));
            }),
            shareReplay(1)).subscribe();

        this.coreAppService.notificationService.latestNotificationSignal.subscribe(this.receiveNotification.bind(this));

    }

    public getPublicChannels(): Observable<Map<number, BehaviorSubject<Channel>>> {
        return this.AllChannelsSubject$.asObservable();
    }

    /**
      * Retrieves a page of messages from the server and adds them to their viewable parent channels.
      * If fetchNewest is false, 100 messages will be fetched backwards in time from the oldest currently loaded message, otherwise only fetches
      * newer messages that have occurred after initial page load.
      */
    public getPublicMessagesPage(fetchNewest: boolean): Observable<MessageResponse> {
        let lastMessageId = null;
        return this.retrieveMessages(fetchNewest ? null : lastMessageId, null, 'public');
    }



    /**
     * HTTP helper method to fetch channels by ID
     */
    public retrievePublicChannels(sinceLast: Date): Observable<MessageResponse> {
        return this.http.get<MessageResponse>(environment.apiUrl + `/chat/channel/public/${sinceLast ? sinceLast.valueOf() : ''}`, this.httpOptionsAuthJson())
            .pipe(
                map(msgResp => {
                    let activeUser = this.coreAppService.userService.activeUser$.getValue();
                    msgResp.Channels = msgResp.Channels.map(channel => new Channel(channel, activeUser.Username));
                    return msgResp;
                }),
                tap(msgResponse => this.updateChannels(msgResponse.Channels)),
                retry(2),
                catchError(this.handleError),
            );
    }

    private receiveNotification(value: Signal) {
        if (value?.SignalType !== SignalType.Notification) return;

        let notification = (<NotificationSignal>value).Notification;
        if (notification.NotificationType != NotificationType.MessageBoard) return;

        let newMessage = <Message>notification.Entity;

        this.insertMessagesToChannel([newMessage]).subscribe((channelAndMessages) => {
        });

        this.notificationService.addNotification(notification);
    }
}
