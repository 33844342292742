<div class="modal-header">
    <h2 class="modal-title">Bug Report!</h2>
    <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
</div>
<div class="modal-body">
    @if (!completedState) {
        <form ngNativeValidate (ngSubmit)="submit(bugForm)" #bugForm="ngForm">
            <p>To help us find and squish your bug, please describe:</p>
            <ul>
                <li>what you were doing when the error occurred</li>
                <li>what you expected to happen</li>
                <li>and what actually happened</li>
            </ul>
            <div class="mb-3">
                <label for="bugDescription" class="form-label">Description:</label>
                <textarea
                    rows="4"
                    wrap="soft"
                    class="form-control"
                    type="text"
                    minlength="20"
                    maxlength="512"
                    multi
                    required
                    name="Description"
                    ngModel>
                </textarea>
            </div>
            @if (errorState) {
                <div class="invalid">Sorry, images must be less than 5mb. This was too large: {{ erroredFile }}</div>
            }
            <div class="mb-3 form-group">
                <label for="formFileMultiple" class="form-label">Screenshot of visibly odd behavior (if applicable)</label>
                <input
                    class="form-control"
                    type="file"
                    accept="image/*"
                    name="BugScreenshots"
                    multiple
                    (change)="handleFileInput($event.target)" />
            </div>
            <div class="d-flex justify-content-end">
                <button class="btn btn-primary text-end" type="submit" [disabled]="errorState">Submit Bug Report</button>
            </div>
        </form>
    } @else {
        <h1 class="text-center"><i class="fa fa-check text-secondary" aria-hidden="true"></i></h1>
        <div class="text-center"><strong>Submitted! Thanks.</strong></div>
        <div class="d-flex justify-content-end">
            <button class="btn btn-primary text-end" type="submit" (click)="closeModal()">Okay</button>
        </div>
    }
</div>
