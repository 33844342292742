import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticatedPageComponent } from 'src/app/components/base.component';
import { MainpageComponent } from 'src/app/components/mainpage/mainpage.component';
import { AuthService } from 'src/app/services/auth.service';
import { UIStateService } from 'src/app/services/ui-state.service';
import { Shop } from 'src/app/dto';
import { ShopService } from 'src/app/services/shop.service';

@Component({
    selector: 'app-shops-manager',
    templateUrl: './shops-manager.component.html',
    styleUrls: ['./shops-manager.component.scss']
})
export class ShopsManagerComponent extends AuthenticatedPageComponent implements OnInit {
    public static route = 'shopsmanager';

    shops: Shop[] = [];
    formShop: Shop = new Shop(null);

    constructor(private shopService: ShopService, private modalService: NgbModal, router: Router, authService: AuthService, uiStateService: UIStateService) {
        super(router, authService, MainpageComponent.route, uiStateService);
    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.shopService.activeUserShops.subscribe(s => {
                this.shops = s;
                this.shops.sort((a, b) => a.Id - b.Id);
            }));
    }

    public updateShop(shop: Shop): void {
        this.subscriptions.push(this.shopService.updateShops(shop).subscribe());
    }

    public updateAllShops(): void {
        this.shops.forEach(s => {
            this.subscriptions.push(
                this.shopService.updateShops(s).subscribe()
            );
        });
    }

    public addNewShop(formShop: Shop): void {
        this.subscriptions.push(
            this.shopService.addShop(formShop).subscribe(s => {
                this.shops.push(s);
                this.formShop = new Shop(null);
            })
        );

    }

    public deleteShop(shop: Shop): void {
        this.subscriptions.push(
            this.shopService.removeShop(shop).subscribe(() => {
                const indexToRemove = this.shops.findIndex(s => shop.Id === s.Id);
                if (indexToRemove !== -1) {
                    this.shops.splice(indexToRemove, 1);
                }
                this.modalService.dismissAll();
            })
        );

    }

    openModal(content: any) {
        this.modalService.open(content);
    }

    closeModal() {
        this.modalService.dismissAll();
    }



}
