<div class="achievement-details">
    @if (!errorState && achievement) {
        <div class="achievement-overlay">
            <p class="achievement-description">{{ achievement.Description }}</p>
        </div>
        <img class="achievement-img" [src]="achievement.ImagePath" alt="{{ achievement.Name }}" />
        <p class="achievement-name">
            <strong>{{ achievement.Name }}</strong>
        </p>
    } @else {
        @if (errorState) {
            <div>Oh no, we couldn't fetch that achievement!</div>
        } @else {
            Loading...
        }
    }
</div>
