import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { NotificationType } from 'angular-to-phaser';
import { Observable, map } from 'rxjs';
import { View } from 'src/app/components/base.component';
import { ShadowPopoverDirective } from 'src/app/directives/shadow-popover.directive';
import { Channel, Friendship, Message, Notification } from 'src/app/dto';
import { ApplicationService } from 'src/app/services/application.service';
import { ChatbarService } from 'src/app/services/chatbar.service';
import { InboxService } from 'src/app/services/inbox.service';
import { NotificationService } from 'src/app/services/notification.service';
import { TutorialService } from 'src/app/services/tutorial.service';

@Component({
    selector: 'app-social-pane',
    templateUrl: './social.component.html',
    styleUrls: ['./social.component.scss']
})
export class SocialComponent extends View implements OnInit, AfterViewInit {

    friends: Observable<Friendship[]>;
    onlineFriends: Observable<Friendship[]>;
    offlineFriends: Observable<Friendship[]>;
    receivedFriendRequests: Observable<Friendship[]>;
    sentFriendRequests: Observable<Friendship[]>;

    unreadMessages: Observable<Message[]>;
    channels$: Observable<Channel[]>;
    activeChannels: Observable<Channel[]>;

    @ViewChild('tut1') tutorial1: ShadowPopoverDirective;


    private sliderControlMediaQuery: MediaQueryList = window.matchMedia('(max-width: 766px)');
    unreadAlerts: Observable<Notification[]>;
    hideShell = false;

    constructor(private coreAppService: ApplicationService,
        private inboxService: InboxService,
        private chatService: ChatbarService,
        private notificationService: NotificationService,
        private tutorialService: TutorialService) {
        super(coreAppService.authService, coreAppService.uiStateService);

        this.channels$ = this.inboxService.getObservableChannels();
        this.activeChannels = this.chatService.ActiveChannels.asObservable();

        this.friends = this.inboxService.friendsList$;
        this.onlineFriends = this.inboxService.onlineFriends$.asObservable();
        this.offlineFriends = this.inboxService.offlineFriends$.asObservable();

        this.unreadAlerts = this.notificationService.unreadPrivateNotifications;
        this.sentFriendRequests = this.inboxService.friendRequests$.pipe(
            map(fs => {
                const user = this.coreAppService.userService.activeUser$.getValue();
                return fs.filter(frq => frq.SenderUsername.localeCompare(user.Username) === 0);
            })
        );
        this.receivedFriendRequests = this.inboxService.friendRequests$.pipe(
            map(fs => {
                const user = this.coreAppService.userService.activeUser$.getValue();
                return fs.filter(frq => frq.RecipientUsername.localeCompare(user.Username) === 0);
            })
        );

        this.subscribe(this.uiStateService.hideApplicationShellElements.asObservable(), hide => this.hideShell = hide);
    }

    ngOnInit(): void {
        this.sliderControlMediaQuery.addEventListener('change', this.setupGlobalHiders.bind(this)); // Attach listener function on state changes
        this.setupGlobalHiders();
    }

    ngAfterViewInit(): void {
        if (!this.tutorialService.tutorialComplete) {
            this.tutorialService.addTutorialSections([this.tutorial1]);
        }
    }

    getMessagePreview(channel: Channel): MessagePreview {
        if (channel.Messages.length === 0) {
            return null;
        } else {
            const message = channel.Messages[0];
            let preview = '';
            if (message.MessageText) {
                preview = message.MessageText.substr(0, Math.min(40, message.MessageText.length));
            };

            return {
                BodyPreview: preview,
                DateSent: message.SendDate,
                SenderUsername: channel.Usernames.find(x => x === message.SenderUsername)
            };
        }
    }

    openChat(withUsername: string) {
        this.uiStateService.hideAll();
        this.chatService.FindOrCreateAndOpenChat(withUsername);
    }

    openChannel(channel: Channel): void {
        this.uiStateService.hideAll();
        this.chatService.OpenChannel(channel);
    }

    closeChannel(channel: Channel): void {
        this.chatService.CloseChannel(channel);
    }

    acceptFriend(friendship: Friendship) {
        if (friendship.targetingNotification) {
            this.subscribe(this.notificationService.markNotificationsAsRead(friendship.targetingNotification), () => { });
        }
        this.subscriptions.push(this.inboxService.addFriend(friendship.SenderUsername).subscribe());
    }

    rejectFriend(friendship: Friendship) {
        if (friendship.targetingNotification) {
            this.subscribe(this.notificationService.markNotificationsAsRead(friendship.targetingNotification), () => { });
        }
        this.subscriptions.push(this.inboxService.removeFriend(friendship.SenderUsername).subscribe());
    }

    private setupGlobalHiders(): void {
        const element = document.getElementById('social-sidebar-container');
        if (this.sliderControlMediaQuery.matches) { // If media query matches and this is a phone-sized-window
            this.uiStateService.addGlobalAutohideCollapser({ name: element.id, element });
        } else { //this bar doesn't need to autohide on desktop
            this.uiStateService.removeGlobalAutohideCollapser(element.id);
        }
    }

    markReadTimeout: NodeJS.Timeout;
    toggleSidebar(): void {
        this.uiStateService.hideAll();
        if (this.markReadTimeout) {
            clearTimeout(this.markReadTimeout);
            this.markReadTimeout = null;
        } else {
            this.markReadTimeout = setTimeout(() => this.subscribe(
                this.notificationService.markNotificationsAsRead(...this.notificationService.persistentPrivateNotifications.getValue().filter(n => n.NotificationType == NotificationType.FriendshipRequest)),
                () => { }),
                2000);
        }
    }
}

export class MessagePreview {
    public BodyPreview: string;
    public DateSent: Date;
    public SenderUsername: string;
}
