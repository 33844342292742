import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AchievementAlert, AchievementConfig, Signal } from '../dto';
import { AbstractService } from './abstractservice';
import { AuthService } from './auth.service';
import { AchievementToast, SignalService } from './signal.service';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root'
})
export class AchievementService extends AbstractService {

    public achievementDetails: BehaviorSubject<AchievementConfig[]> = new BehaviorSubject<AchievementConfig[]>([]);

    constructor(private http: HttpClient, private userService: UserService, private notificationService: SignalService, authService: AuthService) {
        super(authService);
        userService.activeUser$.subscribe(u => {
            if (u && u.Username && this.authService.isAuthenticated()) {
                notificationService.initializeConnection();
            }
        });

        notificationService.latestNotificationSignal.subscribe(this.receiveNotification.bind(this));
    }

    public getAchievements(userName: string): Observable<number[]> {
        return this.http.get<number[]>(`${environment.apiUrl}/achievement/user/${userName}`, this.httpOptionsAuthJson());
    }

    public getAchievementConfig(achievementType: number): Observable<AchievementConfig> {
        // TODO caching
        return this.http.get<AchievementConfig>(`${environment.apiUrl}/achievement/${achievementType}`, this.httpOptionsAuthJson()).pipe(map(ach => new AchievementConfig(ach)));
    }

    /**
     * Handler function for notifications from the NotificationService (sent asynchronously via SignalR)
     * This impl ignores all non NewMessageAlert notifications
     */
    private receiveNotification(value: Signal): void {
        if ((<AchievementAlert>value)?.Achievement) {
            const achievement = new AchievementConfig((<AchievementAlert>value).Achievement);

            //constructor(body: string, title: string, visibleDuration: number | null)
            var toast = new AchievementToast(achievement.Description, achievement.Name, achievement.ImagePath, null);
            if (toast.visibleDuration === 0) return;
            this.notificationService.addToast(toast);
        }
    }
}
