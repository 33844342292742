import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, combineLatest, map, of, switchMap } from 'rxjs';
import { Channel, Message, User, UserProfile } from 'src/app/dto';
import { ApplicationService } from 'src/app/services/application.service';
import { MessageboardService } from 'src/app/services/messageboard.service';
import { AuthenticatedPageComponent } from '../../base.component';
import { MainpageComponent } from '../../mainpage/mainpage.component';
import { MessageBoardIndexComponent } from '../message-board-index/message-board-index.component';

@Component({
    selector: 'app-message-board',
    templateUrl: './message-board.component.html',
    styleUrls: ['./message-board.component.scss']
})
export class MessageBoardComponent extends AuthenticatedPageComponent implements OnInit {

    public static route = 'topic';
    private static MESSAGES_PER_PAGE = 20;

    channelId: number;
    channel$: Observable<Channel>;
    messages$: Observable<Message[]>;
    usersByUsername: Map<string, User>;
    composeMessage = '';

    page: number = 1;
    startIndex = 0;
    endIndex = MessageBoardComponent.MESSAGES_PER_PAGE - 1;
    nextPageExists = false;
    prevPageExists = false;


    constructor(public coreAppService: ApplicationService, private messageBoardService: MessageboardService, private route: ActivatedRoute, router: Router) {
        super(router, coreAppService.authService, MainpageComponent.route, coreAppService.uiStateService);
    }

    ngOnInit(): void {
        this.subscribe(
            combineLatest([this.messageBoardService.getPublicChannels(), this.route.params])
                .pipe(
                    switchMap((channelArrAndRouteParams) => {
                        this.page = Number.parseInt(channelArrAndRouteParams[1].page ?? 1);
                        this.startIndex = ((this.page - 1) * MessageBoardComponent.MESSAGES_PER_PAGE);
                        this.endIndex = (this.page * MessageBoardComponent.MESSAGES_PER_PAGE) - 1;

                        this.channelId = Number.parseInt(channelArrAndRouteParams[1].id);
                        if (this.channelId === null || this.channelId === undefined) {
                            this.router.navigate([MessageBoardIndexComponent.route, 1]);
                            return of(<Channel>null);
                        }

                        let channelSubject = channelArrAndRouteParams[0].get(this.channelId);
                        this.channel$ = channelSubject?.asObservable();
                        if (this.channel$) { //get messages for loaded channel
                            this.messages$ = this.channel$
                                .pipe(
                                    map(channel => {
                                        this.nextPageExists = channel.Messages.length > (this.page * MessageBoardComponent.MESSAGES_PER_PAGE);
                                        this.prevPageExists = this.page !== 1;
                                        return channel.Messages.slice(this.startIndex, this.endIndex + 1);
                                    }));
                        }

                        if (!this.channel$) { //nothing loaded yet
                            return this.messageBoardService.retrieveChannels(null, this.channelId)
                                .pipe(
                                    map(messageResponse => messageResponse.Channels?.length ?
                                        messageResponse.Channels[0] :
                                        <Channel>null));
                        } else { //fetch newest messages for page 1, then work backwards from there
                            return this.messageBoardService.getMessagesPage(this.page === 1, this.channelId, null)
                                .pipe(
                                    map(messageResponse => messageResponse.Channels?.length ? messageResponse.Channels[0] : <Channel>null));
                        }
                    })),
            (channel) => {
                if (!channel && !this.channel$) { //somehow this doesn't exist, abandon ship
                    this.router.navigate([MessageBoardIndexComponent.route, 1]);
                } else {
                    this.subscribe(this.messageBoardService.retrieveChannelUserProfiles(channel.Id), users => {
                        let localMap = new Map<string, UserProfile>();
                        users.forEach(user => localMap.set(user.Username, user));
                        channel.Messages.forEach(m => {
                            if (!m.SenderProfile) {
                                m.SenderProfile = localMap.get(m.SenderUsername);
                            }
                        });
                    });
                    if (channel.ContainsNotification) {
                        setTimeout(() => this.messageBoardService.markNotificationsRead(...channel.Messages
                            .filter(m => m.targetingNotification)), 500);

                        setTimeout(() => this.messageBoardService.markMessagesRead(...channel.Messages
                            .filter(m => !m.Read)), 900);
                    }
                }
            });
    }

    sendMessage(messageText: string, replyMessage: number | null): void {
        const message = new Message(null, null);
        message.ChannelId = this.channelId;
        message.SendDate = new Date(Date.now());
        message.MessageText = messageText;
        message.IsMyMessage = true;
        if (replyMessage) {
            message.ParentMessageId = replyMessage;
        }
        this.messageBoardService.sendMessage(message);
        this.composeMessage = '';
    }


    nextPage() {
        this.page++;
        this.router.navigate([MessageBoardComponent.route, this.channelId, this.page]);
    }

    prevPage() {
        this.page--;
        this.router.navigate([MessageBoardComponent.route, this.channelId, this.page]);
    }


}
