import { Component, OnInit, TemplateRef, ViewChild, } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { View } from 'src/app/components/base.component';
import { MainpageComponent } from 'src/app/components/mainpage/mainpage.component';
import { Interaction, InteractionReply } from 'src/app/dto';
import { AuthService } from 'src/app/services/auth.service';
import { TutorialService } from 'src/app/services/tutorial.service';
import { UIStateService } from 'src/app/services/ui-state.service';
import { ShadowPopoverDirective } from 'src/app/directives/shadow-popover.directive';

@Component({
    selector: 'app-tutorial',
    templateUrl: './tutorial.component.html',
    styleUrls: ['./tutorial.component.scss']
})
export class TutorialComponent extends View implements OnInit {

    @ViewChild('tutorial1') tutorialTemplate1: TemplateRef<any>;
    @ViewChild('tutorial1_5') tutorialTemplate1_5: TemplateRef<any>;
    @ViewChild('tutorial2') tutorialTemplate2: TemplateRef<any>;
    @ViewChild('tutorial3') tutorialTemplate3: TemplateRef<any>;
    @ViewChild('tutorial4') tutorialTemplate4: TemplateRef<any>;
    @ViewChild('tutorial5') tutorialTemplate5: TemplateRef<any>;

    constructor(private activeModal: NgbActiveModal, private modalService: NgbModal, private tutorialService: TutorialService, private router: Router, authService: AuthService, uiStateService: UIStateService) {
        super(authService, uiStateService);
        this.router.navigate([MainpageComponent.route]);
    }

    private static NEXT_STEP_ACTION_CODE = 5;

    private activeTutorialStep: ShadowPopoverDirective;
    private player: Element;

    wizardAction: Interaction;
    errorState = false;
    currentTutorialStep = 0;

    options: InteractionReply[] = [];
    ngOnInit(): void {
        this.collectWizardState();
    }

    chooseOption(npcOption: InteractionReply): void {
        if (npcOption.DisplayMetadata.IsFinalOption) {
            this.activeTutorialStep?.close();
            this.activeModal?.close();
            this.tutorialService.resetTutorialState();
            return;
        }
        if (npcOption.Actions.find(a => a.NextEvent)) {
            this.tutorialService.advanceToSubsequentAction();
            this.collectWizardState();
        } else {
            this.subscriptions.push(this.tutorialService.submitTutorialChoice(npcOption.Actions.find(a => a.DirectAction).DirectAction.ActionCode)
                .subscribe(
                    (npcResponse) => {
                        this.collectWizardState();
                        if (npcOption.DisplayMetadata.IsPrimaryOption && npcResponse.Responses?.length < 1) {
                            this.startNextStep(0);
                            if (this.currentTutorialStep < 5) {
                                this.closeModal();
                            }
                        }
                    }
                ));
        }
    }

    openMenuNextStep(currentStep: number) {
        if (this.activeTutorialStep) { this.activeTutorialStep.close(); }
        setTimeout(() => this.uiStateService.show('main-sidebar-container'), 400);
        setTimeout(() => this.startNextStep(currentStep), 750);
    }

    openUserDetailsNextStep(currentStep: number) {
        if (this.activeTutorialStep) { this.activeTutorialStep.close(); }
        setTimeout(() => this.uiStateService.show('user-details-menu-toggle'), 600);
        this.openMenuNextStep(currentStep);
    }

    closeMenuNextStep(currentStep: number) {
        if (this.activeTutorialStep) { this.activeTutorialStep.close(); }
        this.uiStateService.hide('user-details-menu-toggle');
        this.uiStateService.hide('main-sidebar-container');
        this.startNextStep(currentStep);
    }

    startNextStep(currentStep: number) {
        if (this.activeTutorialStep) { this.activeTutorialStep.close(); }

        if (currentStep < 5) {
            if (currentStep !== 1.5 && currentStep !== 0 && currentStep > this.currentTutorialStep) { this.subscriptions.push(this.tutorialService.submitTutorialChoice(TutorialComponent.NEXT_STEP_ACTION_CODE).subscribe(() => this.currentTutorialStep = this.tutorialService.currentTutorialStep)); }
            this.selectNextPopover();
        }
        if (currentStep >= 5) {
            this.subscriptions.push(this.tutorialService.submitTutorialChoice(TutorialComponent.NEXT_STEP_ACTION_CODE).subscribe(() => {
                this.currentTutorialStep = this.tutorialService.currentTutorialStep;
                this.subscriptions.push(this.tutorialService.determineTutorialCompleteAndSetWizardState().subscribe(() => {
                    this.modalService.open(TutorialComponent); //show end screen
                }));
            }));
        }
    }

    closeModal(): void {
        this.activeModal.close();
    }

    private collectWizardState() {
        this.options = this.tutorialService.tutorialOptions;
        this.wizardAction = this.tutorialService.wizardMessage;
        this.currentTutorialStep = this.tutorialService.currentTutorialStep;

    }

    private selectNextPopover() {
        const sortedElems = this.tutorialService.appShellTutorialSections.concat(this.tutorialService.tutorialSections).sort((a, b) => {
            const tutorialAOrder = this.determineOrder(a.appShadowPopover.toString());
            const tutorialBOrder = this.determineOrder(b.appShadowPopover.toString());
            return tutorialAOrder - tutorialBOrder;
        });
        const nextTutorialElem = sortedElems[0];
        if (!nextTutorialElem) {
            if (this.activeTutorialStep) {
                this.activeTutorialStep.close();
            }
            return;
        }

        this.tutorialService.removeTutorialSection(nextTutorialElem);

        let templateref = null;
        switch (nextTutorialElem.appShadowPopover) {
            case 'tutorial1': templateref = this.tutorialTemplate1; break;
            case 'tutorial1-5': templateref = this.tutorialTemplate1_5; break;
            case 'tutorial2': templateref = this.tutorialTemplate2; break;
            case 'tutorial3': templateref = this.tutorialTemplate3; break;
            case 'tutorial4': templateref = this.tutorialTemplate4; break;
            case 'tutorial5': templateref = this.tutorialTemplate5; break;

            default: templateref = null;
        }
        nextTutorialElem.autoClose = false;
        nextTutorialElem.appShadowPopover = templateref;
        if (this.activeTutorialStep) {
            this.activeTutorialStep.close();
        }

        this.activeTutorialStep = nextTutorialElem;
        nextTutorialElem.open();
    }

    public startAnimation() {
        this.player = document.querySelector('#confetti');
        (<any>this.player).play();
    };

    private determineOrder(tutorialName: string) {
        if (tutorialName) {
            return Number.parseFloat(tutorialName.replace('tutorial', '').replace('-', '.'));
        }
        return 666;
    }
}
