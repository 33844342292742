import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticatedPageComponent } from 'src/app/components/base.component';
import { MainpageComponent } from 'src/app/components/mainpage/mainpage.component';
import { Shop, ShopItem } from 'src/app/dto';
import { ApplicationService } from 'src/app/services/application.service';
import { ShopService } from 'src/app/services/shop.service';
import { UIStateService } from 'src/app/services/ui-state.service';

@Component({
    selector: 'app-shop-editor',
    templateUrl: './shop-editor.component.html',
    styleUrls: ['./shop-editor.component.scss'],
    animations: [UIStateService.ITEM_LEAVE_ANIMATION_DESKTOP, UIStateService.ITEM_LEAVE_ANIMATION_MOBILE]
})
export class ShopEditorComponent extends AuthenticatedPageComponent implements OnInit {
    public static route = 'shop/edit';


    private shopId: number;
    public shop: Shop = new Shop(null);

    constructor(private shopService: ShopService, private route: ActivatedRoute, private modalService: NgbModal, protected coreAppService: ApplicationService, router: Router) {
        super(router, coreAppService.authService, MainpageComponent.route, coreAppService.uiStateService);
    }

    ngOnInit(): void {
        this.route.params.subscribe(params => {
            this.shopId = params.shopId;
            this.shopService.activeUserShops.subscribe(shops => {
                this.shop = shops.filter(s => s.Id == this.shopId)[0];
            });

            this.shopService.getShopItems(this.shopId).subscribe(stockItems => {
                this.shop.Items.push(...stockItems);
            });//getShopItems
        });//routeparams
    }

    public updateShop(shop: Shop): void {
        this.shopService.updateShops(shop).subscribe();
    }

    openModal(content: any) {
        this.modalService.open(content);
    }

}
