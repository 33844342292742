<div id="app" class="app">
    <header id="main-topbar" [ngClass]="{ 'hide-shell': this.hideShell }">
        <nav class="navbar navbar-expand-md p-0">
            <div class="container-fluid d-flex flex-nowrap">
                <a [routerLink]="['/']" class="navbar-brand logo collapse collapse-width show topbar-search-shrinker"
                    ><img src="assets/images/critters-logo.svg"
                /></a>

                <div class="flex-grow-1 flex-nowrap navbar" id="searchbar-container">
                    <ng-template #results let-searchResult="result" let-t="term">
                        <a class="dropdown-item" [routerLink]="['/user/' + searchResult.User.Username]">
                            <strong>{{ searchResult.User.Username }}</strong> ({{ searchResult.User.FirstName }})
                        </a>
                    </ng-template>
                    <div class="ms-auto">
                        <div class="dropdown">
                            <button class="dropdown-toggle" id="search-results-toggler" data-bs-toggle="dropdown"></button>
                            <ul id="search-results" class="dropdown-menu">
                                <li><a class="dropdown-item">No results found</a></li>
                            </ul>
                        </div>
                        <div class="collapse collapse-width main-searchbar topbar-search-shrinker">
                            <input
                                class="col-auto form-control"
                                type="text"
                                id="searchbar"
                                [(ngModel)]="searchResult"
                                placeholder="search"
                                [ngbTypeahead]="this.search"
                                [editable]="false"
                                [resultTemplate]="results"
                                [inputFormatter]="searchFormatter"
                                (ngModelChange)="navigateToSearchResult()"
                                (blur)="mainSearchToggle()"
                                autocomplete="off"
                                autofocus />
                        </div>
                    </div>
                    <button
                        class="search-toggler btn-plain"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target=".topbar-search-shrinker"
                        aria-controls="searchbar"
                        aria-expanded="false"
                        aria-label="Toggle searchbar"
                        appShadowPopover="tutorial4"
                        #tut3="appShadowPopover"
                        triggers="manual">
                        <span class="search-toggler-icon"> <i class="fa fa-search"></i></span>
                    </button>
                </div>

                <button
                    class="navbar-toggler tutorial tutorial1-5"
                    type=" button"
                    data-bs-toggle="collapse"
                    data-bs-target="#main-sidebar-container"
                    aria-controls="main-sidebar-container"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    appShadowPopover="tutorial1-5"
                    #tut2="appShadowPopover"
                    triggers="manual">
                    <span class="navbar-toggler-icon" style="position: relative">
                        @if (forumAlerts | async; as alerts) {
                            @if (alerts?.length) {
                                <div class="alert-dot alert-dot-empty">&nbsp;</div>
                            }
                        }
                        <i class="fa fa-bars"></i>
                    </span>
                </button>
                <div class="collapse navbar-collapse flex-shrink-1">
                    <ng-container class="default-tutorial2" *ngTemplateOutlet="userNav"></ng-container>
                    <ng-template #userNav>
                        @if (isAuthenticated && (activeUser$ | async); as user) {
                            <ul class="navbar-nav">
                                <li class="nav-item" id="mail" style="position: relative">
                                    @if (privateSocialAlerts | async; as alerts) {
                                        @if (alerts.length) {
                                            <div class="alert-dot">{{ alerts.length }}</div>
                                        }
                                    }
                                    <a
                                        class="nav-link"
                                        [routerLink]="['/mailbox']"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#main-sidebar-container">
                                        <img class="nav-icon" src="assets/images/icons/icon-envelope.svg" />
                                        <span class="nav-label d-md-none">Mailbox</span>
                                    </a>
                                </li>
                                <li class="nav-item dropdown">
                                    <a
                                        class="nav-link dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                        id="user-details-menu-toggle"
                                        (appDomCreated)="setupUserHiders($event)">
                                        <img class="nav-icon d-md-none" src="assets/images/icons/icon-user.svg" />
                                        <span class="nav-label" id="username">{{ user.Username }}</span>
                                    </a>
                                    <ul id="user-details-dropdown" class="dropdown-menu" aria-labelledby="user-details-menu-toggle">
                                        <li>
                                            <a
                                                class="dropdown-item"
                                                [routerLink]="['/user/' + user.Username]"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#main-sidebar-container"
                                                >Profile</a
                                            >
                                        </li>
                                        <li>
                                            <a
                                                class="dropdown-item tutorial3"
                                                [routerLink]="['/inventory/']"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#main-sidebar-container"
                                                appShadowPopover="tutorial3"
                                                triggers="manual"
                                                #tut4="appShadowPopover"
                                                >Inventory</a
                                            >
                                        </li>
                                        <li>
                                            <a
                                                class="dropdown-item"
                                                [routerLink]="['/shopsmanager/']"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#main-sidebar-container"
                                                >Shops</a
                                            >
                                        </li>
                                    </ul>
                                </li>
                                <li class="nav-item">
                                    <span class="nav-link">
                                        <span class="nav-label tutorial2"
                                            ><img
                                                src="assets/images/icons/icon-critter-coin.svg"
                                                appShadowPopover="tutorial2"
                                                triggers="manual"
                                                class="nav-icon text-icon tutorial"
                                                #tut1="appShadowPopover" /><strong>{{ user.Cash }}</strong></span
                                        >
                                    </span>
                                </li>
                            </ul>
                        } @else {
                            <ul class="navbar-nav">
                                <li class="nav-item">
                                    <a
                                        class="nav-link"
                                        [routerLink]="['/signup']"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#main-sidebar-container"
                                        >Sign up</a
                                    >
                                </li>
                                <li class="nav-item">
                                    <a
                                        class="nav-link"
                                        [routerLink]=""
                                        (click)="openLoginModal()"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#main-sidebar-container"
                                        >Login</a
                                    >
                                </li>
                            </ul>
                        }
                    </ng-template>
                </div>
            </div>
        </nav>
    </header>

    <div class="app-body">
        <div class="main-sidebar-container collapse collapse-width sidebar" id="main-sidebar-container">
            <div class="sidebar-flex-container">
                <div class="user-details-sidebar-component">
                    @if (isAuthenticated && (activeUser$ | async); as user) {
                        <ng-container class="alt-tutorial2" *ngTemplateOutlet="userNav"></ng-container>
                    } @else {
                        <ul class="navbar-nav">
                            <li class="nav-item">
                                <a
                                    class="nav-link"
                                    [routerLink]="['/signup']"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#main-sidebar-container"
                                    >Sign up</a
                                >
                            </li>
                            <li class="nav-item">
                                <a
                                    class="nav-link"
                                    [routerLink]=""
                                    (click)="openLoginModal()"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#main-sidebar-container"
                                    >Login</a
                                >
                            </li>
                        </ul>
                    }
                </div>
                <nav class="primary-sidebar-component">
                    <ul>
                        <li>
                            <a [routerLink]="['/']" data-bs-toggle="collapse" data-bs-target="#main-sidebar-container"
                                ><img class="nav-icon" src="assets/images/icons/icon-home.svg" />Home</a
                            >
                        </li>
                        <li>
                            <a [routerLink]="['/games/games-list']" data-bs-toggle="collapse" data-bs-target="#main-sidebar-container"
                                ><img class="nav-icon" src="assets/images/icons/icon-games.svg" />Games</a
                            >
                        </li>
                        <li>
                            <a [routerLink]="['/shopfinder/']" data-bs-toggle="collapse" data-bs-target="#main-sidebar-container"
                                ><img class="nav-icon" src="assets/images/icons/icon-shopping-cart.svg" />Shops</a
                            >
                        </li>
                        <li>
                            <a [routerLink]="['/explore']" data-bs-toggle="collapse" data-bs-target="#main-sidebar-container"
                                ><img class="nav-icon" src="assets/images/icons/icon-compass.svg" />World</a
                            >
                        </li>
                        <li style="position: relative">
                            @if (forumAlerts | async; as alerts) {
                                @if (alerts.length) {
                                    <div class="alert-dot">{{ alerts.length }}</div>
                                }
                            }
                            <a [routerLink]="['/boards']" data-bs-toggle="collapse" data-bs-target="#main-sidebar-container"
                                ><img class="nav-icon" src="assets/images/icons/icon-chatter.svg" />Chatter</a
                            >
                        </li>
                    </ul>
                    <ul>
                        @if (
                            isAuthenticated &&
                            !authService.sessionSummary.TutorialComplete &&
                            (tutorialService.tutorialPostponed || !tutorialService.kitTaken)
                        ) {
                            <li class="nav-item mt-auto">
                                <a
                                    class="nav-link"
                                    (click)="openTutorialModal()"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#main-sidebar-container">
                                    <img class="nav-icon" src="assets/images/npcs/setup-wizard.png" />Tutorial
                                </a>
                            </li>
                        }
                        @if (isAuthenticated) {
                            <li class="nav-item mt-auto">
                                <a
                                    class="nav-link"
                                    [routerLink]=""
                                    (click)="openBugModal()"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#main-sidebar-container">
                                    <img class="nav-icon" src="assets/images/icons/icon-bug.svg" />Bug Report
                                </a>
                            </li>
                        }
                    </ul>
                </nav>
            </div>
        </div>
        <div id="main" class="main">
            <app-toast-host></app-toast-host>
            <router-outlet></router-outlet>
        </div>
        @if (isAuthenticated && activeUser.Username) {
            <app-social-pane></app-social-pane>
        }
    </div>
</div>
