<div class="row g-3 align-items-center" id="sendto-container">
    <div class="col-md">
        <div ngClass="form-floating form-control input-tags-collector">
            @for (recipient of recipients; track recipient) {
                <span ngClass="recipient-tag"> {{ recipient }} <i class="fa fa-times" (click)="removeRecipient(recipient)"></i> </span>
            }
            <input
                class="col-auto form-control"
                type="search"
                id="recipientBox"
                [(ngModel)]="searchUsername"
                placeholder="Send To:"
                [ngbTypeahead]="this.search"
                [editable]="false"
                (ngModelChange)="addRecipient()"
                autocomplete="off" />
            <label for="recipientBox">Send To:</label>
        </div>
        <span>
            &nbsp;
            @if (searchFailed) {
                <span class="invalid">No one in your friends list has that name.</span>
            }
        </span>
    </div>
</div>
<div class="d-flex row g-3 align-items-center">
    <div class="col-md">
        <div class="form-floating">
            <input
                type="text"
                id="channelName"
                class="form-control"
                [(ngModel)]="channelName"
                placeholder="Channel name"
                autocomplete="off"
                maxlength="140" />
            <label for="channelName">Thread name:</label>
        </div>
    </div>
    <div class="col-md">
        <div class="form-floating">
            <input type="textarea" id="message" class="form-control" [(ngModel)]="message" placeholder="Message" autocomplete="off" />
            <label for="message">Message:</label>
        </div>
    </div>
    <div class="col-auto">
        <button class="btn btn-primary btn-lg" (click)="createChannel()">Send <i class="fa fa-paper-plane"></i></button>
    </div>
</div>
