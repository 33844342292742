@if (channel$) {
    <div class="conversation-view">
        <div class="title-bar">
            <h1 class="channel-title">{{ (channel$ | async).Name }}</h1>
        </div>
        <div class="conversation-display">
            @for (Message of messages$ | async; track Message) {
                <div>
                    <div class="message">
                        <div class="message-meta">
                            <a class="message-author" [routerLink]="['/user/' + Message.SenderUsername]">
                                @if (Message.SenderProfile && Message.SenderProfile.Image) {
                                    <img [src]="Message.SenderProfile.Image.ImagePath" class="profile-image" />
                                } @else {
                                    <img src="assets/images/users/user-icon.png" class="profile-image" />
                                }
                                <div class="username">{{ Message.SenderUsername }}</div>
                            </a>
                            <div class="message-date">
                                <div class="time">{{ Message.SendDate | date: "h:mm a" }}</div>
                                <div class="date">{{ Message.SendDate | date: "MMM d" }}</div>
                            </div>
                        </div>
                        <div class="message-content">{{ Message.MessageText }}</div>
                    </div>
                </div>
            }
        </div>
        <div class="pagination">
            @if (prevPageExists) {
                <a class="btn btn-light" (click)="prevPage()" aria-label="Newer posts"><i class="fa fa-arrow-left"></i></a>
            }
            @if (nextPageExists) {
                <a class="btn btn-light" (click)="nextPage()" aria-label="Older posts"><i class="fa fa-arrow-right"></i></a>
            }
        </div>
        <div class="composition-box">
            <form class="row">
                <div class="col">
                    <input
                        class="form-control me-2"
                        type="text"
                        name="message"
                        [(ngModel)]="this.composeMessage"
                        placeholder="send a message"
                        required
                        autocomplete="off" />
                </div>
                <div class="col-auto">
                    <button class="btn btn-primary btn-block" type="submit" (click)="sendMessage(this.composeMessage, null)">
                        <i class="fa fa-paper-plane"></i>
                    </button>
                </div>
            </form>
        </div>
    </div>
}
