import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticatedPageComponent } from 'src/app/components/base.component';
import { MainpageComponent } from 'src/app/components/mainpage/mainpage.component';
import { Channel, Message } from 'src/app/dto';
import { AuthService } from 'src/app/services/auth.service';
import { MessageboardService } from 'src/app/services/messageboard.service';
import { UIStateService } from 'src/app/services/ui-state.service';
import { MessageBoardComponent } from '../message-board/message-board.component';


@Component({
    selector: 'app-message-board-compose',
    templateUrl: './message-board-compose.component.html',
    styleUrls: ['./message-board-compose.component.scss']
})
export class MessageBoardComposeComponent extends AuthenticatedPageComponent implements OnInit {

    public static route = 'compose-topic';

    channelName = '';
    message = '';

    constructor(private messageBoardService: MessageboardService, router: Router, authService: AuthService, uiStateService: UIStateService) {
        super(router, authService, MainpageComponent.route, uiStateService);
    }

    ngOnInit(): void {
    }

    createChannel(): void {
        const channel = new Channel(null, this.activeUser.Username);
        channel.Name = this.channelName;
        channel.Usernames = [this.activeUser.Username];
        let message = new Message(null, this.activeUser.Username);
        message.MessageText = this.message;
        message.SendDate = new Date();
        message.SenderUsername = this.activeUser.Username;
        channel.Messages = [message];
        this.subscribe(this.messageBoardService.createChannel(channel, false), (newChannel: Channel) => {
            this.router.navigate([`${MessageBoardComponent.route}/${newChannel.Id}/`]);
        });


    }
}
