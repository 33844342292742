@if (content) {
    <div class="container-fluid">
        @if (content.Title && (this.content.ContentType == 0 || this.content.ContentType == 2)) {
            <div class="page-header row">
                <h1>{{ content.Title }}</h1>
            </div>
        }
        @if (content.Username && content.PublishDate && (this.content.ContentType == 0 || this.content.ContentType == 2)) {
            <p>
                <i>Written by: {{ content.Username }} - {{ content.PublishDate | date: "M/d/yy '-' h:mm a" }}</i>
            </p>
        }
        @if (contentInnerHtml) {
            <div [innerHtml]="contentInnerHtml"></div>
        }
    </div>
}
