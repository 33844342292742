import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WorldService } from 'src/app/services/world.service';
import { Npc } from '../../../dto';
import { AuthService } from '../../../services/auth.service';
import { UIStateService } from '../../../services/ui-state.service';
import { AuthenticatedPageComponent } from '../../base.component';
import { MainpageComponent } from '../../mainpage/mainpage.component';
import { InteractorComponent } from '../../ui/interactor/interactor.component';


@Component({
    selector: 'app-npc',
    templateUrl: './npc.component.html',
    styleUrls: ['./npc.component.scss'],
    animations: [
        trigger('fadeAnimation', [
            transition(':enter', [
                style({ opacity: 0, height: '167px' }), animate('300ms', style({ opacity: 1, height: '*' }))]
            ),
            transition(':leave',
                [style({ opacity: 1, height: '*' }), animate('280ms', style({ opacity: 0, height: '167px' }))]
            )
        ])
    ],
    host: { class: 'full-width' }
})
export class NpcComponent extends AuthenticatedPageComponent implements OnChanges {

    public static route = 'npc';
    private static inputPropertyName = 'npcDetails';
    public activated = false;
    @Input(NpcComponent.inputPropertyName) npcDetails: Npc;
    @Input() injectedClass: string;

    @ViewChild('interactor') interactor: InteractorComponent;

    constructor(private worldService: WorldService, private route: ActivatedRoute, router: Router, authService: AuthService, uiStateService: UIStateService) {
        super(router, authService, MainpageComponent.route, uiStateService);
    }

    activate() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes[NpcComponent.inputPropertyName]) {
            if (this.npcDetails) {
                if (this.interactor) {
                    this.interactor.terminateCurrentInteraction();
                }
                this.subscriptions.push(this.worldService.retrieveNpcOptions(this.npcDetails.ExternalId).subscribe(interaction => {
                    this.activated = true;
                    this.interactor.beginInteraction(interaction, this.npcDetails.Name);
                }));
            }
        }
    }

}
