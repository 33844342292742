@if (userProfile) {
    <div class="row profile-wrapper">
        <div class="profile-container blue-red-gradient">
            <div class="d-flex justify-content-center card p-3">
                @if (viewedUserIsMe) {
                    <div class="edit-view-container">
                        @if (!editing) {
                            <button class="btn btn-link" (click)="editing = !editing">
                                <i class="fa fa-pencil-alt"></i>
                            </button>
                        }
                        @if (editing) {
                            <button class="btn btn-link" (click)="editing = !editing">
                                <i class="fa fa-eye"></i>
                            </button>
                        }
                        <button class="btn btn-link" (click)="updateUserProfile()">
                            <i class="fa fa-save"></i>
                        </button>
                    </div>
                }
                @if (editing) {
                    <form id="profile-edit" class="d-flex justify-content-center flex-column" #profileForm="ngForm" ngNativeValidate>
                        <div class="profile-image-holder">
                            <div class="inline-edit-img">
                                <img class="profile-image shadow-sm" [src]="userProfile.Image.ImagePath" (click)="clickProfileImage()" />
                            </div>
                        </div>
                        <div class="user-info">
                            <h1>{{ user.Username }}</h1>

                            <div class="inline-edit">
                                <input
                                    class="form-control-plaintext input-inline"
                                    type="text"
                                    style="text-align: right; margin-right: 0.25em"
                                    [(ngModel)]="user.FirstName"
                                    placeholder="First Name"
                                    [ngModelOptions]="{ standalone: true }"
                                    autocomplete="off"
                                    required
                                    maxlength="35"
                                    [size]="firstName.value ? firstName.value.length : 10"
                                    #firstName />

                                <input
                                    class="form-control-plaintext input-inline"
                                    type="text"
                                    [(ngModel)]="user.LastName"
                                    placeholder="Last Name"
                                    [ngModelOptions]="{ standalone: true }"
                                    autocomplete="off"
                                    required
                                    maxlength="35"
                                    [size]="lastName.value ? lastName.value.length : 9"
                                    #lastName />
                            </div>
                        </div>
                        <hr style="width: 60%; margin: 10px auto" />
                        <div class="row text-left">
                            <div class="col-md-6">
                                <div class="profile-details">
                                    <strong>Location: </strong>
                                    <div class="inline-edit">
                                        <input
                                            class="input-inline"
                                            type="text"
                                            [(ngModel)]="user.City"
                                            placeholder="City"
                                            [ngModelOptions]="{ standalone: true }"
                                            autocomplete="off"
                                            required
                                            maxlength="35"
                                            style="min-width: 4ch"
                                            [style]="'width: ' + city.value.length + 'ch;'"
                                            #city />,

                                        <!--42ch width empirically determined-->
                                        <select
                                            class="form-select input-inline"
                                            name="Country"
                                            [(ngModel)]="user.Country"
                                            [ngModelOptions]="{ standalone: true }"
                                            id="country"
                                            style="width: 42ch"
                                            [ngClass]="{
                                                invalidField: countrySelect.invalid && (countrySelect.dirty || countrySelect.touched)
                                            }"
                                            #countrySelect="ngModel"
                                            #country
                                            required>
                                            <option value="null" disabled>Country</option>
                                            @for (countries of countries$ | async; track countries) {
                                                <option [ngValue]="countries">{{ countries }}</option>
                                            }
                                        </select>
                                    </div>
                                    <br />
                                    <strong>Age: </strong>{{ calculateAge(user.Birthdate) }} years<br />

                                    <strong>Pronouns: </strong>
                                    <div class="inline-edit">
                                        <select
                                            class="form-select input-inline"
                                            name="Pronouns"
                                            [(ngModel)]="user.Pronouns"
                                            [ngModelOptions]="{ standalone: true }"
                                            [ngClass]="{
                                                invalidField: pronounSelect.invalid && (pronounSelect.dirty || pronounSelect.touched)
                                            }"
                                            #pronounSelect="ngModel"
                                            id="floating-pronouns"
                                            required
                                            style="width: 12ch">
                                            <option value="null" disabled>Pronouns</option>
                                            @for (pronounset of pronouns; track pronounset) {
                                                <option [ngValue]="pronounset.Id">{{ pronounset.FullName }}</option>
                                            }
                                        </select>
                                    </div>
                                    <br />
                                    <br />
                                    <strong class="text-nowrap">Last login: </strong>
                                    {{ userProfile.LastLogin | date: "mediumDate" }},
                                    <span class="text-nowrap">{{ userProfile.LastLogin | date: "shortTime" }}</span> <br />
                                    <strong>Started playing: </strong>{{ user.JoinDate | date: "mediumDate" }}
                                </div>
                            </div>

                            <div class="col-md-6 bio">
                                <textarea
                                    rows="5"
                                    wrap="soft"
                                    class="inline-edit input-inline"
                                    type="text"
                                    maxlength="512"
                                    multi
                                    [(ngModel)]="userProfile.Description"
                                    name="description"
                                    placeholder="Enter a short bio"
                                    style="width: 100%">
                                </textarea>
                            </div>
                        </div>

                        <ng-template #imageSelector>
                            <div class="modal-header">
                                <h2 class="modal-title">Select Profile Image</h2>
                                <button type="button" class="btn-close" aria-label="Close" (click)="cancel()"></button>
                            </div>
                            <div class="modal-body">
                                <div class="row" ngModelGroup="image" #imageSelection="ngModelGroup">
                                    @for (image of availableUserImages | async; track image) {
                                        <label class="col-md-6 col-lg-3">
                                            <input
                                                type="radio"
                                                name="image"
                                                class="radio"
                                                [value]="image"
                                                [(ngModel)]="userProfile.Image" />
                                            <div class="tile" [ngClass]="image.Id === userProfile.Image.Id ? 'selected' : ''">
                                                <img class="pfp-image" [src]="image.ImagePath" />
                                                <div class="pfp-description">
                                                    <strong>{{ image.Name }}</strong
                                                    ><br />
                                                    {{ image.Description }}
                                                </div>
                                            </div>
                                        </label>
                                    }
                                </div>
                            </div>
                            <div class="modal-footer">
                                <div class="d-flex gap-2 justify-content-end">
                                    <button class="btn btn-primary text-end" (click)="selectImage()">Apply</button>
                                    <button type="button" class="btn btn-outline-dark" (click)="cancel()">Cancel</button>
                                </div>
                            </div>
                        </ng-template>
                    </form>
                } @else {
                    <div class="profile-image-holder">
                        <img class="profile-image shadow-sm" [src]="userProfile.Image.ImagePath" />
                    </div>
                    <div class="user-info">
                        <h1>{{ user.Username }}</h1>
                        @if (user.FirstName || user.LastName) {
                            <span>{{ user.FirstName }} {{ user.LastName }}</span>
                        }
                        <hr style="width: 60%; margin: 10px auto" />
                        @if (isAuthenticated && !viewedUserIsMe) {
                            <div class="d-flex justify-content-center">
                                <button class="btn btn-primary circle-button" (click)="sendMessage(user.Username)">
                                    <i class="fa fa-comment"></i>
                                </button>
                                @if (!friendship) {
                                    <button (click)="addFriend(user.Username)" class="btn btn-primary circle-button">
                                        <i class="fa fa-user-plus"></i>
                                    </button>
                                }
                                @if (friendship) {
                                    <span>
                                        @if (friendship.Accepted) {
                                            <button (click)="removeFriend(user.Username)" class="btn btn-primary circle-button">
                                                <span><i class="fa fa-user-times"></i></span>
                                            </button>
                                        } @else {
                                            <button (click)="removeFriend(user.Username)" class="btn btn-primary circle-button">
                                                <span><i class="fa fa-user-times"></i></span>
                                            </button>
                                            @if (friendship.SenderUsername === this.user.Username) {
                                                <button (click)="addFriend(user.Username)" class="btn btn-primary circle-button">
                                                    <span><i class="fa fa-user-check"></i></span>
                                                </button>
                                            }
                                        }
                                    </span>
                                }
                            </div>
                        }
                    </div>
                    <div class="row text-left">
                        <div class="col-md-6">
                            <div class="profile-details">
                                <strong>Location: </strong>{{ user.City }}, {{ user.Country }}<br />
                                <strong>Age: </strong>{{ calculateAge(user.Birthdate) }} years<br />
                                @if (user.Pronouns != null && user.Pronouns != undefined) {
                                    <strong>Pronouns: </strong>{{ user.PronounSet.FullName }}<br />
                                }
                                <br />
                                <strong class="text-nowrap">Last login: </strong>
                                {{ userProfile.LastLogin | date: "mediumDate" }},
                                <span class="text-nowrap">{{ userProfile.LastLogin | date: "shortTime" }}</span> <br />
                                <strong>Started playing: </strong>{{ user.JoinDate | date: "mediumDate" }}
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="profile-description">{{ userProfile.Description }}</div>
                        </div>
                    </div>
                }
            </div>
        </div>
    </div>
    <div class="row justify-content-center">
        @for (pet of userPets; track pet) {
            <div class="pet-container col-md-3">
                <div class="card">
                    <app-profile-pet [petIdData]="pet.Id" class="cardbody"></app-profile-pet>
                </div>
            </div>
        }
    </div>
    @if (userShops && userShops.length > 0) {
        <div class="row justify-content-center">
            <div class="col-12">
                <h4 class="text-center">{{ user.Username }}'s shops:</h4>
            </div>
            @for (userShop of userShops; track userShop) {
                <div [routerLink]="['/shop/' + userShop.Id]" class="col-md-2 shop-container">
                    <div class="card card-hover">
                        <div class="card-body">
                            <div class="shop-img"><img src="assets/images/icons/icon-storefront.svg" /></div>
                            <strong>{{ userShop.Name }}</strong>
                        </div>
                    </div>
                </div>
            }
        </div>
    }

    @if (userAchievements && userAchievements.length > 0) {
        <div class="row justify-content-center">
            <div class="col-12">
                <h4 class="text-center">{{ user.Username }}'s Achievements:</h4>
            </div>
            @for (userAchievement of userAchievements; track userAchievement) {
                <div class="achievement-container col-md-2">
                    <app-achievement-badge [achievementType]="userAchievement" class="card p-3"></app-achievement-badge>
                </div>
            }
        </div>
    }
}
