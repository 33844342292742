import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AnnouncementNotification } from 'src/app/services/signal.service';

@Component({
    templateUrl: './announcement.component.html',
    styleUrls: ['./announcement.component.scss']
})
export class AnnouncementComponent {
    name: string;
    body: string;
    announcement: AnnouncementNotification;

    constructor(public activeModal: NgbActiveModal) { }

    public initialize(announcement: AnnouncementNotification): void {
        this.announcement = announcement;
        this.body = this.announcement.body;
        this.name = this.announcement.title;
        if (this.announcement.eventInformation.PlayerStateUpdate) {
            if (this.announcement.eventInformation.PlayerStateUpdate.AddedItems
                && this.announcement.eventInformation.PlayerStateUpdate.AddedItems.length > 0) {
                this.body += '\r\nYou gain: ';
                this.announcement.eventInformation.PlayerStateUpdate.AddedItems.forEach(i => this.body += '\r\n' + i.ItemType.Name);
            }
            if (this.announcement.eventInformation.PlayerStateUpdate.RemovedItems
                && this.announcement.eventInformation.PlayerStateUpdate.RemovedItems.length > 0) {
                this.body += '\r\nYou lose: ';
                this.announcement.eventInformation.PlayerStateUpdate.RemovedItems.forEach(i => '\r\n' + i.ItemType.Name);
            }
            if (this.announcement.eventInformation.PlayerStateUpdate.CashChangedAmount !== 0) {
                if (this.announcement.eventInformation.PlayerStateUpdate.CashChangedAmount > 0) {
                    this.body += `\r\nYou get: $${this.announcement.eventInformation.PlayerStateUpdate.CashChangedAmount}`;

                } else if (this.announcement.eventInformation.PlayerStateUpdate.CashChangedAmount < 0) {
                    this.body += `\r\nYou lose: $${-1 * this.announcement.eventInformation.PlayerStateUpdate.CashChangedAmount}`;
                }
            }
        }
    }

    public close(): void {
        this.activeModal.close();
        this.announcement.onClose();
    }
}
