<div class="page-header row d-flex align-items-center">
    <div class="col-sm me-auto">
        <h1>Shops!</h1>
    </div>
</div>
<div class="row">
    @for (shop of shops; track shop) {
        <div class="col-md-6 col-lg-3 mb-3 text-center">
            <div class="card card-hover">
                <div class="card-body">
                    @if (shop.ImagePath) {
                        <div class="shop-img">
                            <img class="shopkeeper-img" [src]="shop.ImagePath" />
                        </div>
                    } @else {
                        <div class="shop-img bg-secondary">
                            <img src="assets/images/icons/icon-storefront.svg" />
                        </div>
                    }
                    <h3>
                        <a [routerLink]="['/shop/' + shop.Id]" class="text-reset text-decoration-none stretched-link">
                            {{ shop.Name }}
                        </a>
                    </h3>
                    <i>{{ shop.Description }}</i>
                </div>
            </div>
        </div>
    }
</div>
