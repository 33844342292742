@if (isAuthenticated && (activeUser$ | async); as user) {
    <div class="page-header row d-flex align-items-center">
        <div class="col-sm me-auto">
            <h1>Your dash!</h1>
        </div>
    </div>
    <strong>{{ user.Username }}</strong>
    <div>
        <span class="nav-label"
            ><img src="assets/images/icons/icon-critter-coin.svg" class="text-icon invert" /><strong>{{ user.Cash }}</strong></span
        >
    </div>
    <h3>Your pets</h3>
    <div class="row">
        @for (pet of activeUserPets | async; track pet) {
            <div class="col-md-6 col-lg-3 text-center mb-2">
                <app-profile-pet [petIdData]="pet.Id" class="card"></app-profile-pet>
            </div>
        }
    </div>
}
