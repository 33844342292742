import { Component, Input, OnInit } from '@angular/core';
import { AchievementConfig } from '../../dto';
import { AchievementService } from '../../services/achievement.service';
import { AuthService } from '../../services/auth.service';
import { UIStateService } from '../../services/ui-state.service';
import { View } from '../base.component';


@Component({
    selector: 'app-achievement-badge',
    templateUrl: './achievement-badge.component.html',
    styleUrls: ['./achievement-badge.component.scss']
})
export class AchievementBadgeComponent extends View implements OnInit {
    public static route = 'pet';

    @Input() achievementType: number;
    achievement: AchievementConfig;
    errorState = false;

    constructor(private achievementService: AchievementService, authService: AuthService, uiStateService: UIStateService) {
        super(authService, uiStateService);
    }


    ngOnInit(): void {
        this.errorState = false;

        if (this.achievementType) {
            this.getAchievement();
        } else {
            this.errorState = true;
        }
    }

    private getAchievement() {
        this.subscriptions.push(
            this.achievementService.getAchievementConfig(this.achievementType).subscribe(achievement => {
                if (!achievement) {
                    this.errorState = true;
                }
                this.achievement = achievement;
            })
        );
    }
}
