import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { View } from 'src/app/components/base.component';
import { AuthService } from 'src/app/services/auth.service';
import { UIStateService } from 'src/app/services/ui-state.service';
import { UserService } from 'src/app/services/user.service';
import { ChatbarService } from 'src/app/services/chatbar.service';
import { ToastNotification, SignalService, MessageToast, AnnouncementNotification, FriendshipToast, AchievementToast } from 'src/app/services/signal.service';
import { InboxService } from 'src/app/services/inbox.service';


@Component({
    selector: 'app-toast-host',
    templateUrl: './toast-host.component.html',
    styleUrls: ['./toast-host.component.scss']
})
export class ToastHostComponent extends View {

    public toasts: Observable<ToastNotification[]>;
    public visibleToasts: ToastNotification[] = [];

    constructor(private notificationService: SignalService, private chatService: ChatbarService, private inboxService: InboxService, authService: AuthService, uiStateService: UIStateService) {
        super(authService, uiStateService);
        this.subscriptions.push(notificationService.toasts.subscribe(this.handleToasts.bind(this)));
    }

    private handleToasts(tsts: ToastNotification[]): void {
        for (let i = 0; i < tsts.length; i++) {
            const t = tsts[i];
            if (this.visibleToasts.findIndex(ta => ta === t) < 0) {
                this.visibleToasts.push(t);
            }
        }

        const deletes = [];
        for (let i = 0; i < this.visibleToasts.length; i++) {
            const t = this.visibleToasts[i];

            if (tsts.findIndex(tst => tst === t) === -1) {
                deletes.push(i);
            }
        }//reverse because deleting idx 1 will shift idx 3 into idx 2, messing up future deletes
        deletes.reverse().forEach(idx => this.visibleToasts.splice(idx, 1)); //could be optimized easily to splice adjacent delete idxs
    }

    public viewChannel(toast: MessageToast) {
        this.chatService.OpenChannel(toast.channel);
        toast.onClose();
    }

    public getToastType(toast: ToastNotification) {
        return toast.constructor.name;
    }

    acceptFriend(friendUsername: string, toast: FriendshipToast) {
        this.subscriptions.push(this.inboxService.addFriend(friendUsername).subscribe());
        toast.onClose();
    }

    removeFriend(friendUsername: string, toast: FriendshipToast) {
        this.subscriptions.push(this.inboxService.removeFriend(friendUsername).subscribe());
        toast.onClose();
    }

    public messageToastName: string = MessageToast.name;
    public friendshipToastName: string = FriendshipToast.name;
    public achievementToastName: string = AchievementToast.name;

}
