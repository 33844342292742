<div class="page-header row d-flex align-items-center">
    <div class="col-sm me-auto">
        <h1>Inventory</h1>
    </div>
    <!-- TODO: search and sort
  <div class="col-sm">
    <form class="row">
      <div class="col">
        <input type="text" placeholder="keyword search" class="form-control">
      </div>
      <div class="col-auto">
        <button type="submit" class="btn btn-primary">search</button>
      </div>
    </form>
  </div>
  <div class="col-sm">
    <select class="form-select">
      <option selected>sort</option>
      <option>a</option>
      <option>b</option>
      <option>c</option>
    </select>
  </div>
  -->
</div>
<div class="row">
    @if (coreAppService.uiStateService.isMobile) {
        @for (item of items | async; track item) {
            <app-item-card
                [item]="item"
                [isInventoryContext]="true"
                class="col-md-6 col-lg-3 text-center mb-3"
                @itemRemovalMobile></app-item-card>
        }
    } @else {
        @for (item of items | async; track item) {
            <app-item-card
                [item]="item"
                [isInventoryContext]="true"
                class="col-md-6 col-lg-3 text-center mb-3"
                @itemRemovalDesktop></app-item-card>
        }
    }

    @if ((items | async).length == 0) {
        <div>You don't have any items :(</div>
    }
</div>
