import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticatedPageComponent } from 'src/app/components/base.component';
import { MainpageComponent } from 'src/app/components/mainpage/mainpage.component';
import { Friendship, InventoryItem, ItemUse, PetDTO, Shop } from 'src/app/dto';
import { ApplicationService } from 'src/app/services/application.service';
import { InboxService } from 'src/app/services/inbox.service';
import { InventoryService } from 'src/app/services/inventory.service';
import { PetService } from 'src/app/services/pet.service';
import { ShopService } from 'src/app/services/shop.service';
import { UIStateService } from 'src/app/services/ui-state.service';


@Component({
    selector: 'app-inventory',
    templateUrl: './inventory.component.html',
    styleUrls: ['./inventory.component.scss'],
    animations: [UIStateService.ITEM_LEAVE_ANIMATION_DESKTOP, UIStateService.ITEM_LEAVE_ANIMATION_MOBILE]
})
export class InventoryComponent extends AuthenticatedPageComponent implements OnInit {

    public static route = 'inventory';

    items: Observable<InventoryItem[]>;
    friends: Observable<Friendship[]>;
    shops: Observable<Shop[]>;
    activePets: Observable<PetDTO[]>;

    selectedFriend: string;
    selectedShop: number;
    selectedPet: PetDTO;

    selectedPetOption: { pet: PetDTO; use: ItemUse };

    constructor(protected coreAppService: ApplicationService, private inboxService: InboxService, private inventory: InventoryService, private shopService: ShopService, private pet: PetService, router: Router) {
        super(router, coreAppService.authService, MainpageComponent.route, coreAppService.uiStateService);
        this.items = this.inventory.ActiveUserInventory.asObservable();
        this.friends = this.inboxService.friendsList$;
        this.activePets = this.pet.activeUserPets.asObservable();
        this.shops = this.shopService.activeUserShops;
    }

    ngOnInit(): void {
        this.inventory.initializeInventory();
    }

    dismissModal(modal: any) {
        modal.dismiss();
        return true;
    }
}
