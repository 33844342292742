<div class="default-background-color" [style]="shop.PrecontentCustomCss | cssToNgStylePipe" [ngClass]="shop.PrecontentCssClasses"></div>

<div class="shop-container" [style]="shop.CustomCss | cssToNgStylePipe" [ngClass]="shop.CssClasses">
    @if (!shop.BackgroundImagePath) {
        <div>
            <div class="page-header row">
                <div class="col">
                    <h1>{{ shop.Name }}</h1>
                </div>
                <div class="col-auto">
                    <a class="btn btn-light me-2 btn-back" [routerLink]="['/shopfinder/']">Back to Shops</a>
                    @if (isMe) {
                        <a class="btn btn-primary" [routerLink]="['/shop/edit/' + shop.Id]">Edit Shop</a>
                    }
                </div>
            </div>
            <div class="row">
                <div class="col mb-2">
                    <p>{{ shop.Description }}</p>
                    @if (shop.OwnerUsername) {
                        <p class="mb-0">
                            <i>
                                Owned by <a [routerLink]="['/user/' + shop.OwnerUsername]">{{ shop.OwnerUsername }}</a>
                            </i>
                        </p>
                    }
                </div>
            </div>
        </div>
    } @else {
        <div class="img-banner">
            <img class="bg-img" [src]="shop.BackgroundImagePath" />
            <div class="shop-btns">
                <a class="btn btn-light me-2 btn-back" [routerLink]="['/shopfinder/']">Back to Shops</a>
                @if (isMe) {
                    <a class="btn btn-primary" [routerLink]="['/shop/edit/' + shop.Id]">Edit Shop</a>
                }
            </div>
        </div>
        <div class="row" [ngClass]="{ 'npc-wrapper': shop.OwnerNpc }">
            @if (shop.ImagePath) {
                <div class="col-md-auto text-center">
                    <img class="shopkeeper-img" [src]="shop.ImagePath" />
                </div>
            }
            <div class="col-md shop-description">
                <h1>{{ shop.Name }}</h1>
                <p>{{ shop.Description }}</p>
                @if (shop.OwnerUsername) {
                    <p class="mb-0">
                        <i
                            >Owned by <a [routerLink]="['/user/' + shop.OwnerUsername]">{{ shop.OwnerUsername }}</a></i
                        >
                    </p>
                }
            </div>
            @if (shop.OwnerNpc) {
                <app-npc [npcDetails]="shop.OwnerNpc" [injectedClass]="'shop'" style="flex-grow: 1"></app-npc>
            }
        </div>
    }

    <div class="row">
        @if (coreAppService.uiStateService.isMobile) {
            @for (item of shop.Items; track item) {
                <app-item-card
                    [item]="item"
                    [containingShop]="shop"
                    [isInventoryContext]="false"
                    class="col-md-6 col-lg-3 text-center mb-3"
                    @itemRemovalMobile></app-item-card>
            }
        } @else {
            @for (item of shop.Items; track item) {
                <app-item-card
                    [item]="item"
                    [containingShop]="shop"
                    [isInventoryContext]="false"
                    class="col-md-6 col-lg-3 text-center mb-3"
                    @itemRemovalDesktop></app-item-card>
            }
        }
    </div>
</div>
