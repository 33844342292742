import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Friendship, PetDTO, Pronouns, Shop, User, UserImage, UserProfile } from 'src/app/dto';
import { AchievementService } from 'src/app/services/achievement.service';
import { ApplicationService } from 'src/app/services/application.service';
import { ChatbarService } from 'src/app/services/chatbar.service';
import { InboxService } from 'src/app/services/inbox.service';
import { MetadataService } from 'src/app/services/metadata.service';
import { PetService } from 'src/app/services/pet.service';
import { ShopService } from 'src/app/services/shop.service';
import { PageComponent } from '../base.component';
import { MainpageComponent } from '../mainpage/mainpage.component';





@Component({
    selector: 'app-user',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent extends PageComponent implements OnInit {

    public static route = 'user';

    user: User;// = new User();
    userAchievements: number[] = [];
    userPets: PetDTO[] = [];

    userProfile: UserProfile;// = new UserProfile();
    private originalUserProfileImage: UserImage = null;

    friendship: Friendship;
    userShops: Shop[] = [];
    viewedUserIsMe = false;
    availableUserImages: Observable<UserImage[]>;
    editing: boolean = false;

    @ViewChild('imageSelector') imageSelectTemplate: TemplateRef<any>;
    modal: NgbActiveModal;

    public countries$: Observable<string[]>;
    public pronouns: Pronouns[] = Pronouns.getAll();



    constructor(private coreAppService: ApplicationService, private shopService: ShopService, private chatService: ChatbarService, private petService: PetService, private inboxService: InboxService,
        private route: ActivatedRoute, private router: Router, private achievementService: AchievementService, private metadataService: MetadataService) {
        super(coreAppService.authService, coreAppService.uiStateService);

        this.subscribe(this.activeUser$, (activeUser => {
            if (this.user && this.user.Username && this.user.Username.length > 0) {
                this.initialize(this.user.Username, activeUser);
            }
        }));

        this.countries$ = this.metadataService.getCountries();
    }

    private initialize(userNameParam: string, activeUser: User) {
        this.subscribe(this.coreAppService.userService.retrieveUser(userNameParam)
            .pipe(
                catchError((e, caught) => {
                    if (e.status === 404) {
                        this.router.navigate([`/${MainpageComponent.route}`]);
                    }
                    return throwError(() => e);
                })),
            (retrievedUser => {
                this.user = retrievedUser;
                this.subscriptions.push(this.coreAppService.userService.retrieveUserProfile(this.user.Username).subscribe(um => this.userProfile = um));

                if (this.isMe(activeUser)) {
                    this.subscribe(this.petService.activeUserPets, (pds => this.userPets = pds));
                    this.subscribe(this.shopService.activeUserShops, (shops => this.userShops = shops));
                    this.subscribe(this.achievementService.getAchievements(this.user.Username), (achieves => this.userAchievements = achieves));

                } else {
                    if (this.isAuthenticated) {
                        this.subscribe(this.inboxService.allFriendAndReqs$, allFriends => this.findFriendshipWithProfileUser(allFriends, this.user.Username));
                    }
                    this.subscribe(this.petService.getPetsForUser(this.user.Username), pds => this.userPets = pds);
                    this.subscribe(this.shopService.getUserShops(this.user.Username), uss => this.userShops = uss);
                    this.subscribe(this.achievementService.getAchievements(this.user.Username), achieves => this.userAchievements = achieves);

                }
            }));
    }

    isMe(activeUser: User) {
        this.viewedUserIsMe = false;
        if (activeUser && this.user) {
            this.viewedUserIsMe = activeUser.Username === this.user.Username;
        }
        return this.viewedUserIsMe;
    }

    updateUserProfile() {
        this.subscribe(this.coreAppService.userService.updateUserProfile(this.userProfile), () => { });
        this.subscribe(this.coreAppService.userService.updateUser(this.user), () => { });

        this.editing = false;
    }

    sendMessage(friendUsername: string): void {
        this.chatService.FindOrCreateAndOpenChat(friendUsername);
    }


    addFriend(friendUsername: string): void {
        this.inboxService.addFriend(friendUsername)
            .subscribe();
    }

    removeFriend(friendUsername: string): void {
        this.inboxService.removeFriend(friendUsername)
            .subscribe();
    }

    findFriendshipWithProfileUser(friends: Friendship[], profileUsername: string): void {
        this.friendship = friends.find(fsd => fsd.FriendUsername.localeCompare(profileUsername) === 0);
    }

    calculateAge(birthDate: Date): number {
        const now = new Date();

        return Math.floor((Date.UTC(now.getFullYear(), now.getMonth(), now.getDate()) - Date.UTC(birthDate.getFullYear(), birthDate.getMonth(), birthDate.getDate())) / (1000 * 60 * 60 * 24 * 365.2422));
    }

    clickProfileImage() {
        if (this.isMe) {
            this.originalUserProfileImage = this.userProfile.Image;
            this.availableUserImages = this.coreAppService.unifiedRepo.retrieveWithNoId(true, true, UserImage);
            this.modal = this.coreAppService.modalService.open(this.imageSelectTemplate, { size: 'lg' });
        } else {
            //i dunno about this, open it bigger? TODO
        }
    }

    cancel() {
        this.userProfile.Image = this.originalUserProfileImage;
        this.modal.dismiss();
    }

    selectImage() {
        this.originalUserProfileImage = null;
        this.updateUserProfile();
        this.modal?.close(1);
    }

    ngOnInit(): void {
        this.route.params.subscribe(params => {
            this.initialize(params.username, this.activeUser);
        });
    }




}
