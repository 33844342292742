import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContentType } from 'angular-to-phaser';
import { StaticContent } from 'src/app/dto';
import { ApplicationService } from 'src/app/services/application.service';
import { StaticContentService } from 'src/app/services/staticcontent.service';
import { PageComponent } from '../../base.component';

@Component({
    selector: 'app-static',
    templateUrl: './static.component.html',
    styleUrls: ['./static.component.scss']
})
export class StaticComponent extends PageComponent {

    public static route = "static";
    public content: StaticContent;
    public contentInnerHtml: string;

    public constructor(private staticContentService: StaticContentService, private coreAppService: ApplicationService, private route: ActivatedRoute) {
        super(coreAppService.authService, coreAppService.uiStateService);
        this.route.params.subscribe(params => {
            const id = params.id;
            this.subscribe(this.staticContentService.getStaticContentById(id), staticContent => {
                this.content = staticContent;
                let contentSubpath = this.content.ContentType == ContentType.Absolute ? 'absolute/' :
                    (ContentType.Blog ? 'news/' : '');
                this.staticContentService.getContent(`assets/static/${contentSubpath}${this.content.ContentFilePath}`).subscribe(retrievedContent => this.contentInnerHtml = retrievedContent.toString());
            });
        });
    }

}
