<div class="page-header row">
    <div class="col-sm me-auto">
        <h1>Create a new topic!</h1>
    </div>
    <div class="col-sm-auto">
        <button class="btn btn-light" [routerLink]="['/boards']"><i class="fa fa-arrow-left"></i> Back to message boards</button>
    </div>
</div>
<div class="compose">
    <p>Share a thought with the world! Remember that everyone will be able to read and comment on your post. And, you won't be able to edit
        or delete your posts.</p>
    <form (ngSubmit)="createChannel()" ngNativeValidate>
        <div class="form-floating">
            <input type="text" autocomplete="off" id="channelName" class="form-control" [(ngModel)]="channelName" placeholder="Channel name"
                required minlength="2" maxlength="240" [ngModelOptions]="{standalone: true}">
            <label for="channelName">New topic name:</label>
        </div>
        <div class="form-floating">
            <input type="text" autocomplete="off" id="message" class="form-control" [(ngModel)]="message" placeholder="Message" required
                minlength="2" [ngModelOptions]="{standalone: true}">
            <label for="message">Message:</label>
        </div>
        <input type="submit" class="btn btn-primary btn-lg" value="Submit">
    </form>
</div>