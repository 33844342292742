import { NgModule } from '@angular/core';
import { NavigationEnd, PreloadAllModules, Router, RouterModule, Routes } from '@angular/router';

import { filter, pairwise } from 'rxjs';
import { DashboardComponent } from '../../components/dashboard/dashboard.component';
import { ExploreComponent } from '../../components/explore/explore.component';
import { NpcComponent } from '../../components/explore/npc/npc.component';
import { GenericSelectorComponent } from '../../components/generic-selector/generic-selector.component';
import { InventoryComponent } from '../../components/items/inventory/inventory.component';
import { ShopEditorComponent } from '../../components/items/shops/shop-editor/shop-editor.component';
import { ShopFinderComponent } from '../../components/items/shops/shop-finder/shop-finder.component';
import { ShopViewComponent } from '../../components/items/shops/shop-view/shop-view.component';
import { ShopsManagerComponent } from '../../components/items/shops/shops-manager/shops-manager.component';
import { ComposeComponent } from '../../components/mailbox/compose/compose.component';
import { ConversationComponent } from '../../components/mailbox/conversation/conversation.component';
import { MailboxComponent } from '../../components/mailbox/mailbox.component';
import { MainpageComponent } from '../../components/mainpage/mainpage.component';
import { MessageBoardComposeComponent } from '../../components/message-boards/message-board-compose/message-board-compose.component';
import { MessageBoardIndexComponent } from '../../components/message-boards/message-board-index/message-board-index.component';
import { MessageBoardComponent } from '../../components/message-boards/message-board/message-board.component';
import { ProfilePetComponent } from '../../components/profile-pet/profile-pet.component';
import { ProfileComponent } from '../../components/profile/profile.component';
import { StaticComponent } from '../../components/static-content/static/static.component';

const INTERACTOR_MODAL_OUTLET_NAME = 'interactormodaloutlet';

const routes: Routes = [
    { path: MainpageComponent.route, component: MainpageComponent, },
    { path: DashboardComponent.route, component: DashboardComponent },
    { path: `${ProfileComponent.route}/:username`, component: ProfileComponent },
    { path: `${InventoryComponent.route}`, component: InventoryComponent },
    { path: `${ShopViewComponent.route}/:shopId`, component: ShopViewComponent },
    { path: `${ShopsManagerComponent.route}`, component: ShopsManagerComponent },
    { path: `${ShopEditorComponent.route}/:shopId`, component: ShopEditorComponent },
    { path: `${ShopFinderComponent.route}`, component: ShopFinderComponent },
    { path: `${NpcComponent.route}/:npcId`, component: NpcComponent },
    { path: `${MailboxComponent.route}`, component: MailboxComponent },
    { path: `${ConversationComponent.route}/:channelId`, component: ConversationComponent },
    { path: `${ComposeComponent.route}/:toUsername`, component: ComposeComponent },
    { path: `${ProfilePetComponent.route}/:petId`, component: ProfilePetComponent },
    { path: `${ExploreComponent.route}/:zoneId`, component: ExploreComponent },
    { path: `${ExploreComponent.route}`, component: ExploreComponent },
    { path: `${MessageBoardIndexComponent.route}`, component: MessageBoardIndexComponent },
    { path: `${MessageBoardIndexComponent.route}/:page`, component: MessageBoardIndexComponent },
    { path: `${MessageBoardComponent.route}/:id`, component: MessageBoardComponent },
    { path: `${MessageBoardComponent.route}/:id/:page`, component: MessageBoardComponent },
    { path: `${MessageBoardComposeComponent.route}`, component: MessageBoardComposeComponent },
    { path: `${StaticComponent.route}/:id`, component: StaticComponent },


    { path: `${ProfileComponent.route}-interactor/:username`, component: ProfileComponent, outlet: INTERACTOR_MODAL_OUTLET_NAME },
    { path: `${InventoryComponent.route}-interactor`, component: InventoryComponent, outlet: INTERACTOR_MODAL_OUTLET_NAME },
    { path: `${ShopViewComponent.route}-interactor/:shopId`, component: ShopViewComponent, outlet: INTERACTOR_MODAL_OUTLET_NAME },
    { path: `${ShopsManagerComponent.route}-interactor`, component: ShopsManagerComponent, outlet: INTERACTOR_MODAL_OUTLET_NAME },
    { path: `${ShopEditorComponent.route}-interactor/:shopId`, component: ShopEditorComponent, outlet: INTERACTOR_MODAL_OUTLET_NAME },
    { path: `${ShopFinderComponent.route}-interactor`, component: ShopFinderComponent, outlet: INTERACTOR_MODAL_OUTLET_NAME },
    { path: `${GenericSelectorComponent.route}-interactor`, component: GenericSelectorComponent, outlet: INTERACTOR_MODAL_OUTLET_NAME },
    { path: `${NpcComponent.route}-interactor/:npcId`, component: NpcComponent, outlet: INTERACTOR_MODAL_OUTLET_NAME },

    { path: 'signup', loadChildren: () => import('../../components/signup/signup.module').then(m => m.SignupModule) },
    { path: 'games', loadChildren: () => import('../games/games.module').then(m => m.GamesModule) },
    { path: 'games-modal', loadChildren: () => import('../games/games.module').then(m => m.GamesModule), outlet: INTERACTOR_MODAL_OUTLET_NAME },


    { path: '', redirectTo: `/${MainpageComponent.route}`, pathMatch: 'full' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled', preloadingStrategy: PreloadAllModules })],
    exports: [RouterModule]
})
export class AppRoutingModule {
    constructor(private router: Router) {
        router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            pairwise() // Combine the current and previous NavigationEnd events into pairs
        ).subscribe(([previousEvent, currentEvent]) => {
            const previousUrl = (<NavigationEnd>previousEvent).urlAfterRedirects;
            const currentUrl = (<NavigationEnd>currentEvent).urlAfterRedirects;

            if (!previousUrl.includes(INTERACTOR_MODAL_OUTLET_NAME) && !currentUrl.includes(INTERACTOR_MODAL_OUTLET_NAME)) {
                document.getElementById('main')?.scrollTo({ top: 0, left: 0, behavior: 'instant' });
            }
        });
    }

}
