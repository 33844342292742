import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { APP_BASE_HREF } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { AchievementBadgeComponent } from '../../components/achievement-badge/achievement-badge.component';
import { DashboardComponent } from '../../components/dashboard/dashboard.component';
import { ExploreComponent } from '../../components/explore/explore.component';
import { NpcComponent } from '../../components/explore/npc/npc.component';
import { GenericSelectorComponent } from '../../components/generic-selector/generic-selector.component';
import { InventoryComponent } from '../../components/items/inventory/inventory.component';
import { ShopEditorComponent } from '../../components/items/shops/shop-editor/shop-editor.component';
import { ShopFinderComponent } from '../../components/items/shops/shop-finder/shop-finder.component';
import { ShopViewComponent } from '../../components/items/shops/shop-view/shop-view.component';
import { ShopsManagerComponent } from '../../components/items/shops/shops-manager/shops-manager.component';
import { LoginModalComponent } from '../../components/login-modal/login-modal.component';
import { ComposeComponent } from '../../components/mailbox/compose/compose.component';
import { ConversationComponent } from '../../components/mailbox/conversation/conversation.component';
import { MailboxComponent } from '../../components/mailbox/mailbox.component';
import { MainpageComponent } from '../../components/mainpage/mainpage.component';
import { MessageBoardComposeComponent } from '../../components/message-boards/message-board-compose/message-board-compose.component';
import { MessageBoardIndexComponent } from '../../components/message-boards/message-board-index/message-board-index.component';
import { MessageBoardComponent } from '../../components/message-boards/message-board/message-board.component';
import { AnnouncementComponent } from '../../components/notifications/announcement/announcement.component';
import { ToastHostComponent } from '../../components/notifications/toast-host/toast-host.component';
import { ToastComponent } from '../../components/notifications/toasts/toast.component';
import { ProfilePetComponent } from '../../components/profile-pet/profile-pet.component';
import { ProfileComponent } from '../../components/profile/profile.component';
import { MessagePopupComponent } from '../../components/social/message-popup/message-popup.component';
import { SocialComponent } from '../../components/social/social.component';
import { NewsAlertBannerComponent } from '../../components/static-content/news/news-alert-banner/news-alert-banner.component';
import { NewsTilesComponent } from '../../components/static-content/news/news-tiles/news-tiles.component';
import { StaticComponent } from '../../components/static-content/static/static.component';
import { BugReportComponent } from '../../components/ui/bug-report/bug-report.component';
import { ErrorModalComponent } from '../../components/ui/error-modal/error-modal.component';
import { InteractorComponent } from '../../components/ui/interactor/interactor.component';
import { TutorialComponent } from '../../components/ui/tutorial/tutorial.component';
import { DomCreatedDirective } from '../../directives/dom-created.directive';
import { ShadowPopoverDirective } from '../../directives/shadow-popover.directive';
import { CssStyleToNgStylePipe } from '../../pipes/css-to-ng-style.pipe';
import { SharedModule } from '../shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

@NgModule({ declarations: [
        AppComponent,
        DashboardComponent,
        ProfileComponent,
        SocialComponent,
        MessagePopupComponent,
        InventoryComponent,
        ShopViewComponent,
        ShopsManagerComponent,
        ShopEditorComponent,
        ToastComponent,
        ToastHostComponent,
        AnnouncementComponent,
        MailboxComponent,
        AnnouncementComponent,
        ComposeComponent,
        NpcComponent,
        ConversationComponent,
        ProfilePetComponent,
        MainpageComponent,
        LoginModalComponent,
        ShopFinderComponent,
        BugReportComponent,
        AchievementBadgeComponent,
        TutorialComponent,
        ShadowPopoverDirective,
        DomCreatedDirective,
        ErrorModalComponent,
        InteractorComponent,
        ExploreComponent,
        CssStyleToNgStylePipe,
        GenericSelectorComponent,
        MessageBoardComponent,
        MessageBoardIndexComponent,
        MessageBoardComposeComponent,
        StaticComponent,
        NewsAlertBannerComponent,
        NewsTilesComponent
    ],
    exports: [ShadowPopoverDirective],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA], imports: [SharedModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        FormsModule,
        NgbModule], providers: [
        { provide: APP_BASE_HREF, useValue: environment.baseHref },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule { }
