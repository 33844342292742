<div class="page-header row">
    <div class="col-sm me-auto">
        <h1>Message Boards</h1>
    </div>
    <div class="col-sm-auto">
        <button class="btn btn-primary" [routerLink]="['/compose-topic']"><i class="fa fa-plus"></i> Create a new topic!</button>
    </div>
</div>
<div class="forums-table">
    @for (topic of channels | async; track topic) {
        <div class="forums-row" [routerLink]="['/topic/' + topic.Id]" [ngClass]="{ 'alerted-item': topic.ContainsNotification }">
            <div class="row">
                <div class="col-sm">
                    <strong
                        >{{ topic.Name }}
                        @if (topic.CreatorUsername) {}</strong
                    ><br />
                    <span class="meta">by {{ topic.CreatorUsername }} at {{ topic.CreateDate | date: "h:mm a, MMM d" }}</span>
                </div>
                <div class="col-sm-auto">
                    <span class="date">
                        {{ topic.MessageCount }}
                        @if (topic.MessageCount != 1) {
                            <span>&nbsp;comments</span>
                        } @else {
                            <span>&nbsp;comment</span>
                        }
                        <br />Last activity at {{ topic.Messages[0].SendDate | date: "h:mm a, MMM d" }}</span
                    >
                </div>
            </div>
        </div>
    }
</div>
<div class="pagination">
    @if (prevPageExists) {
        <a class="btn btn-light" (click)="prevPage()" aria-label="Newer posts"><i class="fa fa-arrow-left"></i></a>
    }
    @if (nextPageExists) {
        <a class="btn btn-light" (click)="nextPage()" aria-label="Older posts"><i class="fa fa-arrow-right"></i></a>
    }
</div>
