import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { View } from 'src/app/components/base.component';
import { AuthService } from 'src/app/services/auth.service';
import { UIStateService } from 'src/app/services/ui-state.service';
import { Channel, Message } from '../../../dto';
import { ChatbarService } from '../../../services/chatbar.service';
import { InboxService } from '../../../services/inbox.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
    selector: 'app-message-popup',
    templateUrl: './message-popup.component.html',
    styleUrls: ['./message-popup.component.scss']
})
export class MessagePopupComponent extends View implements OnInit, AfterViewInit {

    @Input() channel: Channel;

    composeMessage = '';
    minimized = false;
    @ViewChild('chatDisplay', { static: false }) scrollingContainer: ElementRef;

    constructor(private inboxService: InboxService, private chatService: ChatbarService, private notificationService: NotificationService, authService: AuthService, uiStateService: UIStateService) {
        super(authService, uiStateService);
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            let alertedItems = this.scrollingContainer.nativeElement.getElementsByClassName('alerted-item');
            if (alertedItems?.length) {
                alertedItems[alertedItems.length - 1]?.scrollIntoView({ behavior: 'smooth' });
                setTimeout(() => this.inboxService.markNotificationsRead(...this.channel.Messages
                    .filter(m => m.targetingNotification)), 500);
            }
            let unreadItems = this.scrollingContainer.nativeElement.getElementsByClassName('unread-item');
            if (unreadItems?.length) {
                setTimeout(() => this.inboxService.markMessagesRead(...this.channel.Messages
                    .filter(m => !m.Read)), 950);
            }
        }, 700);
    }

    toggleChannel(): void {
        this.chatService.CloseChannel(this.channel);
    }

    sendMessage(messageText: string, channel: Channel, replyMessage: number | null): void {
        if (this.channel.Id > 0) {
            const message = new Message(null, null);
            message.ChannelId = channel.Id;
            message.SendDate = new Date(Date.now());
            message.MessageText = messageText;
            message.IsMyMessage = true;
            if (replyMessage) {
                message.ParentMessageId = replyMessage;
            }
            this.inboxService.sendMessage(message);
        } else { //new channel, first message
            const message = new Message(null, null);
            message.MessageText = this.composeMessage;
            message.SendDate = new Date();
            message.IsMyMessage = true;

            this.subscriptions.push(
                this.inboxService.createChannelWithInitialMessage(this.channel, message, true).subscribe(chd => {
                    this.chatService.CloseChannel(this.channel);
                    this.channel = chd;
                    this.chatService.OpenChannel(this.channel);

                })
            );
        }
        this.composeMessage = '';
    }
}
