import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, combineLatest, map } from 'rxjs';
import { Channel } from 'src/app/dto';
import { ApplicationService } from 'src/app/services/application.service';
import { BaseMessagingService } from 'src/app/services/base-messaging.service';
import { MessageboardService } from 'src/app/services/messageboard.service';
import { AuthenticatedPageComponent } from '../../base.component';
import { MainpageComponent } from '../../mainpage/mainpage.component';

@Component({
    selector: 'app-message-board-index',
    templateUrl: './message-board-index.component.html',
    styleUrls: ['./message-board-index.component.scss']
})
export class MessageBoardIndexComponent extends AuthenticatedPageComponent implements OnInit {

    public static route = 'boards';
    private static TOPICS_PER_PAGE = 10;

    channels: Observable<Channel[]>;
    page: number = 1;
    startIndex = 0;
    endIndex = MessageBoardIndexComponent.TOPICS_PER_PAGE - 1;
    nextPageExists = false;
    prevPageExists = false;


    constructor(private route: ActivatedRoute,
        private messageBoardService: MessageboardService,
        private coreAppService: ApplicationService, router: Router) {
        super(router, coreAppService.authService, MainpageComponent.route, coreAppService.uiStateService);
    }

    ngOnInit(): void {
        this.channels = combineLatest([this.messageBoardService.getPublicChannels(), this.route.params])
            .pipe(
                map((channelArrAndRouteParams) => {
                    this.page = Number.parseInt(channelArrAndRouteParams[1].page ?? 1);
                    this.startIndex = ((this.page - 1) * MessageBoardIndexComponent.TOPICS_PER_PAGE);
                    this.endIndex = (this.page * MessageBoardIndexComponent.TOPICS_PER_PAGE) - 1;



                    var allCachedChannels = BaseMessagingService.sortChannelsByMessageRecency([...channelArrAndRouteParams[0].values()]
                        .map(channelSubject => channelSubject.getValue())
                        .filter(channel => channel.IsPublic));

                    if (allCachedChannels.length < (this.page * MessageBoardIndexComponent.TOPICS_PER_PAGE)) {
                        let lastChannel = allCachedChannels.length > 0 ? allCachedChannels[allCachedChannels.length - 1] : null;
                        let lastChannelMessage = lastChannel && lastChannel.Messages.length > 0 ? lastChannel.Messages[lastChannel.Messages.length - 1] : null
                        this.messageBoardService.retrievePublicChannels(lastChannelMessage?.SendDate).subscribe();
                    }

                    if (this.page === 1) {
                        // double-tap retrieve on purpose
                        this.messageBoardService.retrievePublicChannels(null).subscribe();
                    }

                    this.nextPageExists = allCachedChannels.length > (this.page * MessageBoardIndexComponent.TOPICS_PER_PAGE);
                    this.prevPageExists = this.page !== 1;

                    return allCachedChannels.slice(this.startIndex, this.endIndex + 1);
                }
                ));
    }

    nextPage() {
        this.page++;
        this.router.navigate([MessageBoardIndexComponent.route, this.page]);
    }

    prevPage() {
        this.page--;
        this.router.navigate([MessageBoardIndexComponent.route, this.page]);
    }
}
