import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { ToastNotification } from 'src/app/services/signal.service';

@Component({
    selector: 'app-toast',
    templateUrl: './toast.component.html',
    styleUrls: ['./toast.component.scss']
})
export class ToastComponent {

    @Input() public template: TemplateRef<any>;
    @Input() public toastNotification: ToastNotification;


    public onClose(toast: ToastNotification) {
        toast.onClose();
    }


}

