import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EMPTY, Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { NestableAuthenticatedView } from 'src/app/components/base.component';
import { MainpageComponent } from 'src/app/components/mainpage/mainpage.component';
import { AuthService } from 'src/app/services/auth.service';
import { UIStateService } from 'src/app/services/ui-state.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-bug-report',
    templateUrl: './bug-report.component.html',
    styleUrls: ['./bug-report.component.scss']
})
export class BugReportComponent extends NestableAuthenticatedView {


    constructor(private activeModal: NgbActiveModal, private httpClient: HttpClient, router: Router, route: ActivatedRoute, authService: AuthService, uiStateService: UIStateService) {
        super(router, route, null, authService, MainpageComponent.route, uiStateService);
    }
    errorState = false;
    completedState = false;
    erroredFile = '';

    private uploadImages = [];

    submit(bugForm: NgForm): void {
        const formData = new FormData();
        formData.append('Description', bugForm.value.Description);
        for (let i = 0; i < this.uploadImages.length; i++) {
            formData.append(`BugScreenshots`, this.uploadImages[i]);
        }
        this.subscriptions.push(this.httpClient.post<void>(environment.apiUrl + '/feedback/bugReport', formData,
            {
                withCredentials: true,
                headers: new HttpHeaders({
                    Authorization: 'Bearer ' + this.authService.jwtToken
                })
            }
        ).pipe(
            retry(2),
            catchError(this.handleError),
        ).subscribe(() => { this.completedState = true; }));
    }

    private validateFile(image: File) {
        if (image.size > 5000000) {
            //set error text
            throw 'image too large';
        }
    }

    handleFileInput(eventTarget: EventTarget) {
        const input = <HTMLInputElement>eventTarget;
        this.uploadImages = [];

        const validImages = [];
        this.errorState = false;
        this.erroredFile = '';

        for (let i = 0; i < input.files.length; i++) {
            const img = input.files.item(i);
            try {
                this.validateFile(img);
                validImages.push(img);
            } catch {
                this.erroredFile = img.name;
                this.errorState = true;
                return;
            }
        }
        this.uploadImages = validImages;
    }

    private handleError(error: HttpErrorResponse): Observable<never> {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong.
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        return EMPTY;
    }

    closeModal(): void {
        this.activeModal.close();
    }

}
