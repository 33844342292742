import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Channel, Friendship } from '../../dto';
import { AuthService } from '../../services/auth.service';
import { ChatbarService } from '../../services/chatbar.service';
import { InboxService } from '../../services/inbox.service';
import { UIStateService } from '../../services/ui-state.service';
import { UserService } from '../../services/user.service';
import { AuthenticatedPageComponent } from '../base.component';
import { MainpageComponent } from '../mainpage/mainpage.component';
import { MessagePreview } from '../social/social.component';
import { ComposeComponent } from './compose/compose.component';
import { ConversationComponent } from './conversation/conversation.component';

@Component({
    selector: 'app-mailbox',
    templateUrl: './mailbox.component.html',
    styleUrls: ['./mailbox.component.scss']
})
export class MailboxComponent extends AuthenticatedPageComponent implements OnInit {

    public static route = 'mailbox';

    friends: Observable<Friendship[]>;
    inboundFriendRequests: Friendship[];
    sentFriendRequests: Friendship[];


    channels: Observable<Channel[]>;
    activeChannels: Observable<Channel[]>;
    composeOpen = false;



    constructor(public userService: UserService, private inboxService: InboxService, private chatService: ChatbarService, authService: AuthService, router: Router, uiStateService: UIStateService) {
        super(router, authService, MainpageComponent.route, uiStateService);
        this.channels = this.inboxService.getObservableChannels();
        this.activeChannels = this.chatService.ActiveChannels.asObservable();
        this.friends = this.inboxService.friendsList$;

        this.subscribe(this.inboxService.friendRequests$, allReqs => {
            const user = this.activeUser$.getValue();
            this.sentFriendRequests = (allReqs ?? []).filter(req => req.SenderUsername.localeCompare(user.Username) === 0);
            this.inboundFriendRequests = (allReqs ?? []).filter(req => req.RecipientUsername.localeCompare(user.Username) === 0);
        });
    }

    ngOnInit(): void {
    }

    openChannel(channel: Channel): void {
        this.chatService.OpenChannel(channel);
        //this.chatService.UnreadMessages todo remove from unread messages
        //todo send read receipt
    }

    openChat(friendUsername: string) {
        const privateChat = this.inboxService.findPrivateChat(friendUsername);
        if (!privateChat) { //compose view
            const link = [`/${ComposeComponent.route}/${friendUsername}`];
            this.router.navigate(link);
        } else { //conversation view
            const link = [`/${ConversationComponent.route}/${privateChat.Id}`];
            this.router.navigate(link);
        }
    }

    getMessagePreview(channel: Channel): MessagePreview {
        if (channel.Messages.length === 0) {
            return null;
        } else {
            1
            const message = channel.Messages[0];
            let preview = '';
            if (message.MessageText) {
                preview = message.MessageText.substring(0, Math.min(40, message.MessageText.length));
            };

            return {
                BodyPreview: preview,
                DateSent: message.SendDate,
                SenderUsername: channel.Usernames.find(x => x === message.SenderUsername)
            };
        }
    }

    acceptFriend(friendUsername: string) {
        this.subscriptions.push(this.inboxService.addFriend(friendUsername).subscribe());
    }

    removeFriend(friendUsername: string) {
        this.subscriptions.push(this.inboxService.removeFriend(friendUsername).subscribe());

    }
}
