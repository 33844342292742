import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Channel } from '../dto';
import { AbstractService } from './abstractservice';
import { AuthService } from './auth.service';
import { InboxService } from './inbox.service';


@Injectable({
    providedIn: 'root'
})
export class ChatbarService extends AbstractService {

    constructor(auth: AuthService, private inboxService: InboxService) {
        super(auth);
    }

    public ActiveChannels: BehaviorSubject<Channel[]> = new BehaviorSubject<Channel[]>([]);
    private maxActiveChannels = 5;

    public OpenChannel(channel: Channel) {
        const activeChannels = this.ActiveChannels.getValue();

        //make sure its not already open
        const openedChannelIndex = activeChannels.findIndex(ch => ch.Id === channel.Id);
        if (openedChannelIndex >= 0) {
            if (openedChannelIndex === 0) { //activating a channel already at the prime position does nothing

                return; //(todo "active" might mean on a hotbar but not opened. Not currently!)

            } //but activating a channel at the back of the pack should highlight it by moving it up!
            const openedChannel = activeChannels.splice(openedChannelIndex, 1)[0];
            activeChannels.unshift(openedChannel);
        } else {
            activeChannels.unshift(channel); //might need to fetch this from the inbox svc
            while (activeChannels.length > this.maxActiveChannels) { activeChannels.pop(); }
        }
        this.ActiveChannels.next(activeChannels);
    }

    public CloseChannel(channel: Channel) {
        const activeChannels = this.ActiveChannels.getValue();

        //make sure its actually open
        const openedChannelIndex = activeChannels.findIndex(ch => ch.Id === channel.Id);
        if (openedChannelIndex >= 0) {
            const openedChannel = activeChannels.splice(openedChannelIndex, 1)[0];
            this.ActiveChannels.next(activeChannels);
        }

    }

    public FindOrCreateAndOpenChat(username: string) {
        const privateMessageChannel = this.inboxService.findPrivateChat(username) ?? this.findOpenTempChannel(username);
        if (privateMessageChannel) {
            if (privateMessageChannel.Id >= 0) { this.OpenChannel(privateMessageChannel); }
        } else {
            const newChannel = new Channel(null, null);
            newChannel.Id = Math.round(Math.random() * -100000); //negative is invalid, use huge negative range as a temporary ID
            newChannel.Name = `${this.authService.authenticatedUser$.getValue().Username}, ${username}`;
            newChannel.Usernames = [this.authService.authenticatedUser$.getValue().Username, username];
            this.OpenChannel(newChannel);
        }
    }

    private findOpenTempChannel(username: string) {
        const activeChannels = this.ActiveChannels.getValue().filter(ch => ch.Usernames.length === 2);
        const tempPrivateMessage = activeChannels.find(ch => ch.Usernames.includes(username));
        return tempPrivateMessage;

    }

}
