<ng-container *ngFor="let toast of visibleToasts" [ngSwitch]="this.getToastType(toast)">

    <ng-template #messageToast>
        <div (click)="viewChannel($any(toast))">
            {{$any(toast).message | slice:0:120}}
        </div>
    </ng-template>
    <app-toast *ngSwitchCase="this.messageToastName" [template]="messageToast" [toastNotification]="toast"></app-toast>

    <ng-template #friendshipToast>
        <div *ngIf="$any(toast).friendshipDetails.FriendUsername as friendUsername">
            <a [routerLink]="['/user/' + friendUsername]">
                {{$any(toast).message}}
            </a>
            <span *ngIf="!$any(toast).friendshipDetails.Accepted">
                <button class="btn btn-success me-auto" (click)="acceptFriend(friendUsername, $any(toast))"><i
                        class="fa fa-check"></i></button>
                <button class="btn btn-danger" (click)="removeFriend(friendUsername, $any(toast))"><i class="fa fa-times"></i></button>
            </span>
        </div>

    </ng-template>
    <app-toast *ngSwitchCase="this.friendshipToastName" [template]="friendshipToast" [toastNotification]="toast"></app-toast>

    <ng-template #achievementToast>
        <div class="d-flex align-items-center gap-2">
            <img [src]="$any(toast).image" class="achievement-img">
            <p>
                <strong>{{$any(toast).name}}</strong><br />
                {{$any(toast).body}}
            </p>
        </div>

    </ng-template>
    <app-toast *ngSwitchCase="this.achievementToastName" [template]="achievementToast" [toastNotification]="toast"
        [header]="'Achievement Unlocked'"></app-toast>

    <ng-template #defaultToast>
        {{$any(toast).body}}
    </ng-template>
    <app-toast *ngSwitchDefault [template]="defaultToast" [toastNotification]="toast"></app-toast>

</ng-container>