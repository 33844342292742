import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticatedPageComponent } from 'src/app/components/base.component';
import { MainpageComponent } from 'src/app/components/mainpage/mainpage.component';
import { GenericModalComponent } from 'src/app/components/ui/generic-modal/generic-modal.component';
import { Shop, ShopItem } from 'src/app/dto';
import { ApplicationService } from 'src/app/services/application.service';
import { ShopService } from 'src/app/services/shop.service';
import { UIStateService } from 'src/app/services/ui-state.service';
import { InventoryItemOptionsComponent } from '../../inventory-item-options/inventory-item-options.component';

@Component({
    selector: 'app-shop-view',
    templateUrl: './shop-view.component.html',
    styleUrls: ['./shop-view.component.scss'],
    host: { class: 'full-width' },
    animations: [UIStateService.ITEM_LEAVE_ANIMATION_DESKTOP, UIStateService.ITEM_LEAVE_ANIMATION_MOBILE]
})

export class ShopViewComponent extends AuthenticatedPageComponent implements OnInit {
    public static route = 'shop';

    private shopId: number;
    public shop: Shop = new Shop(null);
    public isMe = false;

    constructor(private shopService: ShopService, protected coreAppService: ApplicationService, private route: ActivatedRoute, router: Router) {
        super(router, coreAppService.authService, MainpageComponent.route, coreAppService.uiStateService);
    }

    displayItemOptions(item: ShopItem) {
        this.coreAppService.uiStateService.openModalWithComponent(
            { size: 'lg', },
            this.shop.Name,
            item.ItemType.Name,
            InventoryItemOptionsComponent,
            GenericModalComponent,
            (invOptions: InventoryItemOptionsComponent, openedModal: NgbActiveModal) => { invOptions.item = item; invOptions.containingShop = this.shop; }
        );

    }


    ngOnInit(): void {
        this.route.params.subscribe(params => {
            this.shopId = params.shopId;

            const myShop = this.shopService.activeUserShops.getValue().filter(s => s.Id === this.shopId);
            if (myShop && myShop.length > 0) {
                this.shop = myShop[0];
                this.isMe = true;
                this.fetchShopstock();
            } else {
                this.subscribe(this.shopService.getShop(this.shopId), s => {
                    this.shop = s;
                    //make sure this wasnt a race condition and we somehow hadn't loaded activeUserShops
                    const activeUser = this.coreAppService.userService.activeUser$.getValue();
                    if (activeUser && this.shop.OwnerUsername) {
                        this.isMe = activeUser.Username === this.shop.OwnerUsername;
                    }
                    this.fetchShopstock();
                });
            }
        });
    }

    private fetchShopstock() {
        this.subscribe(this.shopService.getShopItems(this.shopId), (stock => {
            this.shop.Items.push(...stock)
        }));
    }
}
