<div class="page-header row">
    <h1>Shops Manager</h1>
</div>
<form id="shops-table">
    @if (shops.length < 1) {
        <div class="shops-row">You have no shops. :( Create one below!</div>
    }
    @for (shop of shops; track shop) {
        <div class="shops-row">
            <div class="row">
                <div class="col-sm-3">
                    <input
                        class="form-control-plaintext edit text-bold me-3"
                        type="text"
                        [(ngModel)]="shop.Name"
                        [ngModelOptions]="{ standalone: true }"
                        autocomplete="off"
                        required />
                </div>
                <div class="col-sm">
                    <input
                        class="form-control-plaintext edit me-3"
                        type="text"
                        [(ngModel)]="shop.Description"
                        [ngModelOptions]="{ standalone: true }"
                        autocomplete="off"
                        required />
                </div>
                <div class="col-sm-auto">
                    <a class="btn btn-link" [routerLink]="['/shop/' + shop.Id]"><i class="fa fa-eye"></i></a>
                    <a class="btn btn-link" [routerLink]="['/shop/edit/' + shop.Id]"><i class="fa fa-pencil-alt"></i></a>
                    <ng-template #deleteConfirmation let-modal>
                        <div class="modal-header">
                            <h3>Wait!</h3>
                            <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
                        </div>
                        <div class="modal-body">
                            <p>
                                Are you sure you want to delete <strong>{{ shop.Name }}</strong
                                >?
                            </p>
                            <button (click)="deleteShop(shop)" class="btn btn-danger me-3">Delete</button>
                            <button (click)="closeModal()" class="btn btn-light">Cancel</button>
                        </div>
                    </ng-template>
                    <button (click)="openModal(deleteConfirmation)" class="btn btn-link me-3"><i class="fa fa-trash"></i></button>
                </div>
            </div>
        </div>
    }
    @if (shops.length > 1) {
        <div class="shops-row">
            <button (click)="updateAllShops()" class="btn btn-primary">Update All Shops</button>
        </div>
    }
</form>

<h2>Add a shop</h2>
<form class="row g-2 pb-3" style="max-width: 800px">
    <div class="col-12">
        <div class="form-floating me-3">
            <input
                class="form-control"
                type="text"
                maxlength="40"
                id="floatingShopName"
                placeholder="Shop name"
                [(ngModel)]="formShop.Name"
                [ngModelOptions]="{ standalone: true }"
                autocomplete="off"
                required />
            <label for="floatingShopName">Shop name</label>
        </div>
    </div>

    <div class="col-12">
        <div class="form-floating me-3">
            <textarea
                class="form-control"
                maxlength="200"
                id="floatingShopDescription"
                placeholder="Shop description"
                style="height: 100px !important"
                [(ngModel)]="formShop.Description"
                [ngModelOptions]="{ standalone: true }"
                required></textarea>
            <label for="floatingShopDescription">Shop description</label>
        </div>
    </div>

    <div class="col-12">
        <button (click)="addNewShop(formShop)" class="btn btn-primary">Submit</button>
    </div>
</form>
