<div class="social-wrapper">
    <div class="social-toggle" id="social-bar-toggler" [ngClass]="{ 'hide-shell': this.hideShell }">
        @if (unreadAlerts | async; as alerts) {
            @if (alerts.length) {
                <div class="alert-dot">{{ alerts.length }}</div>
            }
        }
        <button
            (click)="toggleSidebar()"
            class="btn btn-primary tutorial5"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#social-sidebar-container"
            appShadowPopover="tutorial5"
            #tut1="appShadowPopover"
            triggers="manual"
            container="body"
            placement="start">
            <i class="fa fa-comments"></i>
        </button>
    </div>
    <div id="social-sidebar-container" class="collapse collapse-width sidebar">
        <div id="message-sidebar" class="accordion message-sidebar">
            @if (receivedFriendRequests | async; as receivedRequests) {
                <div id="friend-requests-header" class="accordion-item">
                    <div class="accordion-header">
                        <h4 class="mb-0">
                            <button
                                class="accordion-button"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#friend-requests"
                                aria-expanded="true"
                                aria-controls="friend-requests">
                                Friend Requests
                            </button>
                        </h4>
                    </div>
                    <div
                        id="friend-requests"
                        class="accordion-collapse collapse show"
                        aria-labelledby="friend-requests-header"
                        data-parent="#message-sidebar">
                        <div class="accordion-body">
                            @for (friend of receivedRequests; track friend) {
                                <div [ngClass]="{ 'alerted-item': friend.targetingNotification && !friend.targetingNotification.ReadDate }">
                                    @if (friend.FriendUsername; as friendUsername) {
                                        <div class="friend-item d-flex align-items-center">
                                            <a [routerLink]="['/user/' + friendUsername]"
                                                ><img [src]="friend.Profile.Image.ImagePath" class="profile-image"
                                            /></a>
                                            <div>
                                                <a [routerLink]="['/user/' + friendUsername]">{{ friendUsername }}</a
                                                ><br />
                                                <button class="btn btn-sm btn-success me-1" (click)="acceptFriend(friend)">
                                                    <i class="fa fa-user-check"></i>
                                                </button>
                                                <button class="btn btn-sm btn-danger" (click)="rejectFriend(friend)">
                                                    <i class="fa fa-user-times"></i>
                                                </button>
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
            }

            <div id="friend-lists-header" class="accordion-item">
                <div class="accordion-header">
                    <h4 class="mb-0">
                        <button
                            class="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#friend-lists"
                            aria-expanded="true"
                            aria-controls="friend-lists">
                            Friends List
                        </button>
                    </h4>
                </div>
                <div
                    id="friend-lists"
                    class="accordion-collapse collapse show"
                    aria-labelledby="friend-lists-header"
                    data-parent="#message-sidebar">
                    <div class="accordion-body">
                        @for (friend of onlineFriends | async; track friend) {
                            <div>
                                @if (friend.FriendUsername; as friendUsername) {
                                    <div class="friend-item dropdown">
                                        <img
                                            [src]="friend.Profile.Image.ImagePath"
                                            class="profile-image"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false" />
                                        <a (click)="openChat(friendUsername)"
                                            >{{ friendUsername }}<i class="fa fa-circle online-circle" aria-hidden="true"></i>
                                        </a>
                                        <ul class="dropdown-menu">
                                            <li><a [routerLink]="['/user/' + friendUsername]" class="dropdown-item">View Profile</a></li>
                                        </ul>
                                    </div>
                                }
                            </div>
                        }

                        @for (friend of offlineFriends | async; track friend) {
                            <div>
                                @if (friend.FriendUsername; as friendUsername) {
                                    <div class="friend-item">
                                        <img
                                            [src]="friend.Profile.Image.ImagePath"
                                            class="profile-image"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false" />
                                        <a (click)="openChat(friendUsername)">{{ friendUsername }}</a>
                                        <ul class="dropdown-menu">
                                            <li><a [routerLink]="['/user/' + friendUsername]" class="dropdown-item">View Profile</a></li>
                                        </ul>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>

            <div id="conversations-header" class="accordion-item">
                <div class="accordion-header">
                    <h4 class="mb-0">
                        <button
                            class="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#conversations"
                            aria-expanded="true"
                            aria-controls="conversations">
                            Conversations
                        </button>
                    </h4>
                </div>
                <div
                    id="conversations"
                    class="accordion-collapse collapse show"
                    aria-labelledby="conversations-header"
                    data-parent="#message-sidebar">
                    <div class="accordion-body">
                        @for (channel of channels$ | async; track channel) {
                            <div
                                class="message-preview"
                                (click)="openChannel(channel)"
                                [ngClass]="{ 'alerted-item': channel.ContainsNotification }">
                                <strong>{{ channel.Name }}</strong>
                                @if (getMessagePreview(channel); as prev) {
                                    <div>
                                        <div>
                                            <span class="message-text">{{ prev.SenderUsername }}: {{ prev.BodyPreview }}<br /></span>
                                            <span class="message-timestamp">{{ prev.DateSent | date: "shortTime" }}</span>
                                        </div>
                                    </div>
                                } @else {
                                    <i>It's quiet...</i>
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
@if (isAuthenticated && (activeUser$ | async); as activeUser) {
    <div id="bottom-bar-message-host">
        @for (channel of activeChannels | async; track channel) {
            <div>
                <app-message-popup [channel]="channel"></app-message-popup>
            </div>
        }
    </div>
}
