<div class="modal-header">
    <h2 class="modal-title">Welcome to Critters!</h2>
</div>
<div class="modal-body">
    <div class="d-flex align-items-center gap-2 mb-4">
        <img class="profile-image profile-image-lg shadow-lg" src="assets/images/npcs/setup-wizard.png" />
        <p>{{ wizardAction.Message }}</p>
    </div>

    <div class="row justify-content-center">
        <div class="col-auto">
            @if (
                wizardAction.PlayerStateUpdate &&
                wizardAction.PlayerStateUpdate.AddedItems &&
                wizardAction.PlayerStateUpdate.AddedItems.length > 0
            ) {
                <div id="interaction-view" class="results card" class="text-center">
                    <app-critter-state-update [stateUpdate]="wizardAction"></app-critter-state-update>
                </div>
            }
        </div>
    </div>

    <div class="d-flex gap-3 justify-content-end align-items-center">
        @for (option of options; track option) {
            <button
                type="button"
                class="btn text-bold"
                [ngClass]="{
                    'btn-primary order-last': option.DisplayMetadata.IsPrimaryOption,
                    'p-0': !option.DisplayMetadata.IsPrimaryOption,
                    'link-danger order-first': option.DisplayMetadata.IsDangerOption,
                    'link-primary': !option.DisplayMetadata.IsDangerOption && !option.DisplayMetadata.IsPrimaryOption
                }"
                (click)="chooseOption(option)">
                {{ option.OptionText }}
            </button>
        }
    </div>
</div>

<ng-template #tutorial1>
    <p>Detailed info about your critter, like their hunger, mood, and health, plus access to your shops and inventory.</p>
    <button type="button" class="btn btn-sm btn-info" (click)="startNextStep(1)">Got it</button>
</ng-template>

<ng-template #tutorial1_5>
    <p>Tap here to open your menu. It has personal things like your mailbox, user info, and shops.</p>
    <button type="button" class="btn btn-sm btn-info" (click)="openMenuNextStep(1.5)">Got it</button>
</ng-template>

<ng-template #tutorial2>
    <p>See how many crittercoins you have. Earn more by playing minigames! Crittercoins can be exchanged for goods and services.</p>
    <button type="button" class="btn btn-sm btn-info" (click)="openUserDetailsNextStep(2)">Got it</button>
</ng-template>

<ng-template #tutorial3>
    <p>Browse your inventory for food, toys and other fun items for your critters.</p>
    <button type="button" class="btn btn-sm btn-info" (click)="closeMenuNextStep(3)">Got it</button>
</ng-template>

<ng-template #tutorial4>
    <p>You can search for other players!</p>
    <button type="button" class="btn btn-sm btn-info" (click)="startNextStep(4)">Got it</button>
</ng-template>

<ng-template #tutorial5>
    <p>This is the social pane. Explore your messages, online friends, and friend requests.</p>
    <button type="button" class="btn btn-info" (click)="startNextStep(5)">Got it</button>
</ng-template>
