import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticatedPageComponent } from 'src/app/components/base.component';
import { MainpageComponent } from 'src/app/components/mainpage/mainpage.component';
import { Channel, Message, UserProfile } from 'src/app/dto';
import { ApplicationService } from 'src/app/services/application.service';
import { InboxService } from 'src/app/services/inbox.service';
import { MailboxComponent } from '../mailbox.component';

@Component({
    selector: 'app-conversation',
    templateUrl: './conversation.component.html',
    styleUrls: ['./conversation.component.scss']
})
export class ConversationComponent extends AuthenticatedPageComponent implements OnInit, AfterViewInit {

    public static route = 'mailbox/conversation';

    channelId: number;
    activeChannel: Channel = null;
    composeMessage = '';

    @ViewChild('chatDisplay', { static: false }) scrollingContainer: ElementRef;

    channels: Observable<Channel[]>;

    constructor(private inboxService: InboxService, private route: ActivatedRoute, coreAppService: ApplicationService, router: Router) {
        super(router, coreAppService.authService, MainpageComponent.route, coreAppService.uiStateService);
        this.channels = this.inboxService.getObservableChannels();
        this.subscribe(this.channels, this.updateActiveChannel.bind(this));

        this.subscribe(this.route.params, params => {
            this.channelId = Number.parseInt(params.channelId);
            // this.inboxService.getMessagesBatch(this.channelId);
            if (!this.updateActiveChannel()) { //if we don't have this channel loaded, go back to mailbox
                const link = [`/${MailboxComponent.route}`];
                this.router.navigate(link);
            } else {
                this.subscribe(this.inboxService.retrieveChannelUserProfiles(this.activeChannel.Id), users => {
                    let localMap = new Map<string, UserProfile>();
                    users.forEach(user => localMap.set(user.Username, user));
                    this.activeChannel.Messages.forEach(m => {
                        if (!m.SenderProfile) {
                            m.SenderProfile = localMap.get(m.SenderUsername);
                        }
                    });
                });
            };
        });
    }

    updateActiveChannel(): boolean {
        if (this.channelId !== null) {
            this.activeChannel = this.inboxService.AllChannelsSubject$.getValue().get(this.channelId)?.getValue();
            return true;
        }
        return false;
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            let alertedItems = this.scrollingContainer.nativeElement.getElementsByClassName('alerted-item');
            if (alertedItems?.length) {
                alertedItems[alertedItems.length - 1]?.scrollIntoView({ behavior: 'smooth' });
            }
            setTimeout(() => this.inboxService.markNotificationsRead(...this.activeChannel.Messages
                .filter(m => m.targetingNotification)), 500);

            setTimeout(() => this.inboxService.markMessagesRead(...this.activeChannel.Messages
                .filter(m => !m.Read)), 900);

        }, 700);
    }

    sendMessage(messageText: string, replyMessage: number | null): void {
        const message = new Message(null, null);
        message.ChannelId = this.activeChannel.Id;
        message.SendDate = new Date(Date.now());
        message.MessageText = messageText;
        message.IsMyMessage = true;
        if (replyMessage) {
            message.ParentMessageId = replyMessage;
        }
        this.inboxService.sendMessage(message);
        this.composeMessage = '';
    }

    ngOnInit(): void {
    }
}
