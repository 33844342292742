@if (activeChannel) {
    <div class="conversation-view">
        <h1 class="channel-title">{{ activeChannel.Name }}</h1>
        <div class="conversation-display" #chatDisplay>
            @for (Message of activeChannel.Messages; track Message) {
                <div [ngClass]="{ 'alerted-item': Message.targetingNotification && !Message.targetingNotification.ReadDate }">
                    <div class="d-flex align-items-center" [ngClass]="{ 'unread-item': !Message.Read }">
                        <div><img [src]="Message.SenderProfile.Image.ImagePath" class="profile-image" />{{ Message.SenderUsername }}</div>
                        <div class="ms-auto">{{ Message.SendDate | date: "shortTime" }}</div>
                    </div>
                    {{ Message.MessageText }}<br />
                    <hr />
                </div>
            }
        </div>
        <div class="composition-box">
            <form class="row">
                <div class="col">
                    <input
                        class="form-control me-2"
                        type="text"
                        name="message"
                        [(ngModel)]="this.composeMessage"
                        placeholder="send a message"
                        required
                        autocomplete="off" />
                </div>
                <div class="col-auto">
                    <button class="btn btn-primary btn-block" type="submit" (click)="sendMessage(this.composeMessage, null)">
                        <i class="fa fa-paper-plane"></i>
                    </button>
                </div>
            </form>
        </div>
    </div>
}
