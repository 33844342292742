@if (channel) {
    <div class="message-popup">
        <div class="chat-header d-flex justify-content-between">
            <div class="channel-title">{{ channel.Name }}</div>
            <div class="channel-toggles col-auto">
                <button class="btn-plain me-3" (click)="minimized = !minimized">
                    <i class="fa" [ngClass]="{ 'fa-minus': !minimized, 'fa-plus': minimized }"></i></button
                ><button class="btn-plain" (click)="toggleChannel()"><i class="fa fa-times"></i></button>
            </div>
        </div>
        <div class="chat-body" [ngClass]="{ minimized: minimized }">
            <div class="chat-display d-flex" #chatDisplay>
                @for (message of channel.Messages; track message) {
                    <div>
                        <div
                            [ngClass]="{
                                'unread-item': !message.Read,
                                'my-message': message.IsMyMessage,
                                'not-my-message': !message.IsMyMessage,
                                'message-holder': !message.IsMyMessage,
                                'alerted-item': message.targetingNotification && !message.targetingNotification.ReadDate
                            }">
                            <div class="sender">{{ message.SenderUsername }}:</div>
                            <div class="message-text">
                                <div>
                                    <!--internal div necessary to keep there from being lines between the background color-->
                                    {{ message.MessageText }}
                                </div>
                            </div>
                            <div class="date-sent">
                                {{ message.SendDate | date: "short" }}
                            </div>
                        </div>
                    </div>
                }
            </div>
            <form class="composition-box d-flex">
                <input
                    class="form-control me-2"
                    type="text"
                    name="message"
                    [(ngModel)]="this.composeMessage"
                    placeholder="send a message"
                    required
                    autocomplete="off" />
                <button class="btn btn-primary btn-block" type="submit" (click)="sendMessage(this.composeMessage, channel, null)">
                    <i class="fa fa-paper-plane"></i>
                </button>
            </form>
        </div>
    </div>
}
