<div class="page-header row align-items-center">
    <div class="col">
        <div class="form-floating">
            <input
                class="form-control h1"
                type="text"
                maxlength="40"
                [(ngModel)]="shop.Name"
                style="font-size: unset !important; background-color: transparent"
                placeholder="Shop Name"
                id="floatingShopName"
                autocomplete="off"
                required />
            <label for="floatingShopName">Shop Name</label>
        </div>
    </div>
    <div class="col-auto">
        <button (click)="updateShop(shop)" class="btn btn-primary">Update</button>
        <a class="btn btn-primary ms-2" [routerLink]="['/shop/' + shop.Id]">View Shop</a>
    </div>
</div>
<div class="row mb-3">
    <div class="col">
        <div class="form-floating">
            <textarea
                class="form-control"
                maxlength="200"
                [(ngModel)]="shop.Description"
                placeholder="Shop Description"
                id="floatingShopDescription"
                style="height: 100px"
                required></textarea>
            <label for="floatingShopDescription">Shop Description</label>
        </div>
    </div>
</div>

<div class="row">
    @if (coreAppService.uiStateService.isMobile) {
        @for (item of shop.Items; track item) {
            <app-item-card
                [item]="item"
                [containingShop]="shop"
                [isInventoryContext]="false"
                [isShopManagementContext]="true"
                class="col-md-6 col-lg-3 text-center mb-3"
                @itemRemovalMobile></app-item-card>
        }
    } @else {
        @for (item of shop.Items; track item) {
            <app-item-card
                [item]="item"
                [containingShop]="shop"
                [isInventoryContext]="false"
                [isShopManagementContext]="true"
                class="col-md-6 col-lg-3 text-center mb-3"
                @itemRemovalDesktop></app-item-card>
        }
    }
</div>
