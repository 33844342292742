import { AfterViewInit, Directive, ElementRef, EventEmitter, OnDestroy, Output } from '@angular/core';

@Directive({
    selector: '[appDomCreated]',
    exportAs: 'appDomCreated'
})
export class DomCreatedDirective implements OnDestroy, AfterViewInit {

    @Output()
    public appDomCreated = new EventEmitter();


    constructor(private elementRef: ElementRef) {

    }

    ngAfterViewInit(): void {
        const element = this.elementRef.nativeElement;
        this.appDomCreated.emit({ target: element });
    }

    ngOnDestroy(): void {
        this.appDomCreated.complete();
    }
}
