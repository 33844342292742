import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LocationType } from 'angular-to-phaser';
import { Npc, Zone } from '../../dto';
import { AuthService } from '../../services/auth.service';
import { UIStateService } from '../../services/ui-state.service';
import { WorldService } from '../../services/world.service';
import { PageComponent } from '../base.component';
import { InteractorComponent } from '../ui/interactor/interactor.component';

@Component({
    selector: 'app-explore',
    templateUrl: './explore.component.html',
    styleUrls: ['./explore.component.scss'],
    host: { class: 'full-width' }
})
export class ExploreComponent extends PageComponent implements OnInit {

    public static route: string = "explore"

    public LocationType = LocationType;
    @ViewChild('interactor') interactor: InteractorComponent;

    private zoneId: string;
    zone: Zone;
    selectedNpc: Npc = null;
    constructor(private route: ActivatedRoute, private worldService: WorldService, authService: AuthService, uiStateService: UIStateService) { super(authService, uiStateService) }

    activateNpc(npc: Npc) {
        this.selectedNpc = npc;
    }

    ngOnInit(): void {
        this.route.params.subscribe(params => {
            this.zoneId = params.zoneId;
            if (this.zoneId) {

                this.worldService.getZone(this.zoneId).subscribe(zone => {
                    this.zone = zone;
                    if (this.zone.OwnerNpcExternalId) { //zone IS A npc (at their home)
                        this.subscriptions.push(this.worldService.retrieveNpcOptions(this.zone.OwnerNpcExternalId).subscribe(interaction => {
                            this.interactor.beginInteraction(interaction, this.zone.Name);
                        }));
                    } else if (this.zone.Interactive) {
                        this.subscriptions.push(this.worldService.retrieveZoneOptions(this.zone.ExternalId).subscribe(interaction => {
                            this.interactor.beginInteraction(interaction, this.zone.Name);
                        }));
                    }
                });
            } else { //no parent zone = top level = THE WORLD
                this.zone = new Zone(<any>{
                    Name: "Explore the World!",
                    Description: "These are all the lands that have been discovered, where will you visit first?",
                    BackgroundImagePath: "world-background.png",
                    OutsideImagePath: "world-background.png"
                });

                this.worldService.getWorld().subscribe(zones => {
                    this.zone.Zones = zones;
                    this.zone = new Zone(this.zone);
                });
            }
        });
    }

}
