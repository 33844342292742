import { AfterViewInit, Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationStart, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of, OperatorFunction } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { View } from '../../components/base.component';
import { LoginModalComponent } from '../../components/login-modal/login-modal.component';
import { BugReportComponent } from '../../components/ui/bug-report/bug-report.component';
import { TutorialComponent } from '../../components/ui/tutorial/tutorial.component';
import { ShadowPopoverDirective } from '../../directives/shadow-popover.directive';
import { Notification, SearchResult2 } from '../../dto';
import { AchievementService } from '../../services/achievement.service';
import { ApplicationService } from '../../services/application.service';
import { MessageboardService } from '../../services/messageboard.service';
import { NotificationService } from '../../services/notification.service';
import { RandomEventService } from '../../services/randomevent.service';
import { TutorialService } from '../../services/tutorial.service';
import { UIStateService } from '../../services/ui-state.service';

declare const gtag: Function;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent extends View implements OnInit, AfterViewInit {
    title = 'Critters!';
    @ViewChildren('tut1') tutorial1: QueryList<ShadowPopoverDirective>;
    @ViewChild('tut2') tutorial2: ShadowPopoverDirective;
    @ViewChild('tut3') tutorial3: ShadowPopoverDirective;
    @ViewChildren('tut4') tutorial4: QueryList<ShadowPopoverDirective>;

    hideShell = false;

    privateSocialAlerts: Observable<Notification[]>;
    forumAlerts: Observable<Notification[]>;



    constructor(private applicationService: ApplicationService,
        private notificationService: NotificationService, private messageBoardService: MessageboardService,
         /* Do Not Remove, needed to ensure events are firing!*/ private randomEvents: RandomEventService, private achievementService: AchievementService,
        private modalService: NgbModal, private router: Router, public tutorialService: TutorialService) {

        super(applicationService.authService, applicationService.uiStateService);
        this.configureRouter();
        this.subscriptions.push(this.uiStateService.hideApplicationShellElements.asObservable().subscribe(hide => this.hideShell = hide));
        this.privateSocialAlerts = this.notificationService.unreadPrivateNotifications;
        this.forumAlerts = this.notificationService.unreadMessageboardNotifications;

    }

    private configureRouter() {
        this.router.routeReuseStrategy.shouldReuseRoute = (future: ActivatedRouteSnapshot, currentRoute: ActivatedRouteSnapshot) => {
            this.uiStateService.hideAll();
            return future.url.every(seg => currentRoute.url.includes(seg));
        };
        this.router.onSameUrlNavigation = 'reload';
        this.subscriptions.push(this.router.events
            .pipe(
                filter((e) => e instanceof NavigationStart)
            ).subscribe(() => { this.uiStateService.hideAll(); }));
    }


    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        this.setupGlobalHiders();
        this.setupSearchBarShrinkers();
        const autoCleanTutorial1 = this.tutorial1.changes.subscribe(() => {
            if (this.tutorial1.length > 0 && this.tutorial2 !== null && this.tutorial3 !== null && this.tutorial4.length > 0) {
                this.setupTutorialElements();
            }
            autoCleanTutorial1.unsubscribe();
        });
        const autoCleanTutorial4 = this.tutorial4.changes.subscribe(() => {
            if (this.tutorial1.length > 0 && this.tutorial2 !== null && this.tutorial3 !== null && this.tutorial4.length > 0) {
                this.setupTutorialElements();
            }
            autoCleanTutorial4.unsubscribe();
        });
    }


    public searchFormatter = (x: SearchResult2) => this.searchString;

    searchString = '';
    searching = false;
    searchFailed = false;
    searchResult: SearchResult2;
    //changing this into a normal goodboy function (param){ return val; }
    //instead of an expression body member loses 'this' context
    //because JS is still a crime against humanity.
    search: OperatorFunction<string, readonly SearchResult2[]> = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(300),
            distinctUntilChanged(),
            tap((str) => { this.searching = true; this.searchString = str; }),
            switchMap(term =>
                this.applicationService.unifiedRepo.userRepository.searchUsers(term).pipe(
                    tap((searchResults) => {
                        this.searchFailed = (!searchResults || searchResults.Users.length === 0) && term.length > 0;
                        if (this.searchFailed) {
                            this.uiStateService.show('search-results-toggler');
                        } else {
                            this.uiStateService.hide('search-results-toggler');
                        }
                    }),
                    map(res => res.Users.map(u => new SearchResult2(null, u))),
                    catchError(() => {
                        this.searchFailed = true;
                        this.uiStateService.show('search-results-toggler');
                        return of([]);
                    })
                )
            ),
            tap(() => this.searching = false));

    navigateToSearchResult() {
        if (this.searchResult) {
            if (this.searchResult.User) {
                this.searchString = '';
                this.router.navigate([`/user/${this.searchResult.User.Username}`]);
                this.searchResult = null;
            }
        }
    }

    mainSearchToggle() {
        this.uiStateService.toggle('searchbarToggle1');
        this.uiStateService.toggle('searchbarToggle0');

    }

    public setupUserHiders(event: Event) {
        const userDropdown = (<Element>event.target);
        this.uiStateService.addGlobalAutohideDropdown({ name: userDropdown.id, element: userDropdown });
    }

    private setupGlobalHiders(): void {
        const searchResultToggler = document.getElementById('search-results-toggler');
        const sideBarCollapsers = (<Element[]>[].slice.call(document.querySelectorAll('.sidebar'))).map(el => ({ name: el.id, element: el }));

        this.uiStateService.addGlobalAutohideDropdown({ name: searchResultToggler.id, element: searchResultToggler });
        this.uiStateService.addGlobalAutohideCollapsers(sideBarCollapsers);

        document.getElementById('main').addEventListener('click', () => this.uiStateService.hideAll());
    }

    private setupSearchBarShrinkers(): void {
        const searchBars = (<Element[]>[].slice.call(document.querySelectorAll('.topbar-search-shrinker')));
        let i = 0;
        searchBars.map(shrinky => this.uiStateService.addToggler(`searchbarToggle${i++}`, UIStateService.GetOrCreateCollapser(shrinky)));

        const mainSearchBar = document.getElementsByClassName('main-searchbar')[0];

        const searchToggleFn = () => {
            if (document.activeElement.id !== 'searchbar' && mainSearchBar.classList.contains('show')) {
                this.uiStateService.toggle('searchbarToggle1');
                this.uiStateService.toggle('searchbarToggle0');
            }
        };
        this.uiStateService.addGlobalToggle(searchToggleFn);
        document.getElementById('searchbar-container').addEventListener('mouseleave', searchToggleFn);

        const searchBarInput = document.getElementById('searchbar');
        searchBarInput.blur();
        mainSearchBar.addEventListener('shown.bs.collapse', function () {
            searchBarInput.focus();
        });
        mainSearchBar.addEventListener('hidden.bs.collapse', function () {
            searchBarInput.blur();
        });
    }

    openBugModal() {
        this.modalService.open(BugReportComponent);
    }

    openLoginModal() {
        this.modalService.open(LoginModalComponent);
    }

    openTutorialModal(): void {
        this.tutorialService.showTutorial();
    }

    setupTutorialElements() {
        if (!this.authService.sessionSummary.TutorialComplete && !this.tutorialService.alreadyTutorialPrompted) {
            this.tutorialService.addTutorialComponent(TutorialComponent);
            const tutorials = [];

            const filteredTut1s = this.tutorial1.toArray().filter(vc => {
                const rect = vc.domElement.parentElement.parentElement.parentElement.parentElement.parentElement.getBoundingClientRect();
                return !!rect &&
                    rect.width > 0 && rect.height > 0;
            });
            if (filteredTut1s.length === 0) {
                this.tutorial1.last.container = 'body';
                tutorials.push(this.tutorial1.last); //second one is mobile
            } else {
                tutorials.push(this.tutorial1.first);
            }
            const mobileMenuHamburgerRect = this.tutorial2.domElement.getBoundingClientRect();
            if (!!mobileMenuHamburgerRect && mobileMenuHamburgerRect.width > 0 && mobileMenuHamburgerRect.height > 0) {
                tutorials.push(this.tutorial2);
            }
            tutorials.push(this.tutorial3);

            const filteredTut4s = this.tutorial4.toArray().filter(vc => {
                const rect = vc.domElement.parentElement.parentElement.parentElement.parentElement.parentElement.getBoundingClientRect();
                return !!rect &&
                    rect.width > 0 && rect.height > 0;
            });
            if (filteredTut4s.length === 0) {
                this.tutorial4.last.container = 'body';
                tutorials.push(this.tutorial4.last); //second one is mobile slider, hidden by default
            } else {
                this.tutorial4.first.container = 'body';
                tutorials.push(this.tutorial4.first);
            }

            this.tutorialService.addAppShellTutorialSection(tutorials);
        }
    }


    closeModal(): void {
        this.modalService.dismissAll();
    }
}
