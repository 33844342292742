import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SignalType } from 'angular-to-phaser';
import { AnnouncementComponent } from 'src/app/components/notifications/announcement/announcement.component';
import { RandomEventNotification, Signal } from '../dto';
import { InventoryService } from './inventory.service';
import { AnnouncementNotification, SignalService } from './signal.service';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root'
})
export class RandomEventService {
    private modalRef: NgbModalRef;

    constructor(private userService: UserService, private inventory: InventoryService, private notification: SignalService, private modalService: NgbModal) {
        this.notification.latestNotificationSignal.asObservable().subscribe(this.receiveNotification.bind(this));
    }

    private receiveNotification(value: Signal): void {
        if (value?.SignalType !== SignalType.Notification) { return; }

        if (!(<RandomEventNotification>value).PlayerStateUpdate
            || (!(<RandomEventNotification>value).PlayerStateUpdate.AddedItems
                && !(<RandomEventNotification>value).PlayerStateUpdate.RemovedItems
                && !(<RandomEventNotification>value).PlayerStateUpdate.CashChangedAmount)) {
            return;
        }
        const serverNotification = <RandomEventNotification>value;
        const ann = new AnnouncementNotification(serverNotification.AlertText, 'Something happened!', serverNotification);

        if (!this.modalRef || !this.modalRef.componentInstance) {
            this.modalRef = this.modalService.open(AnnouncementComponent);
            (<AnnouncementComponent>this.modalRef.componentInstance).initialize(ann);
        }

        this.notification.addAnnouncement(ann);

        const user = this.userService.activeUser$.getValue();
        this.userService.activeUser$.next(user);

        if (serverNotification.PlayerStateUpdate) {
            user.Cash += serverNotification.PlayerStateUpdate.CashChangedAmount;

            if (serverNotification.PlayerStateUpdate) {
                this.inventory.insertItems(...serverNotification.PlayerStateUpdate.AddedItems);
                this.inventory.insertItems(...serverNotification.PlayerStateUpdate.RemovedItems);
            }
        }

    }
}
