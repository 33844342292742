import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { NgStyle } from '@angular/common';

@Pipe({
    name: 'cssToNgStylePipe'
})
export class CssStyleToNgStylePipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) { }

    transform(styleString: string): SafeStyle {
        if (!styleString) {
            styleString = "";
        }
        var ret = this.sanitizer.bypassSecurityTrustStyle(styleString);
        return ret;
    }
}