import { Component, OnInit } from '@angular/core';
import { PetDTO } from '../../dto';
import { Observable } from 'rxjs';
import { UserService } from '../../services/user.service';
import { AuthService } from '../../services/auth.service';
import { PetService } from '../../services/pet.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MainpageComponent } from '../mainpage/mainpage.component';
import { AuthenticatedPageComponent } from '../base.component';
import { UIStateService } from '../../services/ui-state.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent extends AuthenticatedPageComponent {

    public static route = 'dashboard';

    activeUserPets: Observable<PetDTO[]>;

    constructor(private userService: UserService, private petService: PetService, route: ActivatedRoute, router: Router, authService: AuthService, uiStateService: UIStateService) {
        // super(router, route, 'dashboard', authService, MainpageComponent.route, uiStateService);
        super(router, authService, MainpageComponent.route, uiStateService);
        this.activeUserPets = this.petService.activeUserPets.asObservable();
    }
}
