@if (zone) {
    <div
        class="default-background-color"
        style="background-color: #142c4c"
        [style]="zone.PrecontentCustomCss | cssToNgStylePipe"
        [ngClass]="zone.PrecontentCssClasses"></div>
    <div
        class="zone-container"
        [style]="zone.CustomCss | cssToNgStylePipe"
        [class]="!zone.ParentZone ? 'top-level' : 'child'"
        [ngClass]="zone.CssClasses">
        <div class="img-banner">
            <img class="bg-img" [src]="zone.BackgroundImagePath" />
            <div class="row adhere-left-edge">
                <div [class]="!zone.ParentZone ? 'col-md-6 col-lg-4' : 'col'">
                    <div class="npc-details">
                        <span [hidden]="selectedNpc">
                            @if (zone.ImagePath) {
                                <img class="zone-image" [src]="zone.ImagePath" />
                            }
                            <h1>{{ zone.Name }}</h1>
                            <p class="description">{{ zone.Description }}</p>
                            <app-interactor #interactor [injectedClass]="zone.OwnerNpcExternalId ? 'npc' : 'zone'"> </app-interactor>
                        </span>
                        @if (selectedNpc) {
                            <div class="selected-npc-wrapper">
                                <app-npc [npcDetails]="selectedNpc" [injectedClass]="'explore'" style="flex-grow: 1"></app-npc>
                                <button type="button" class="btn-close" aria-label="Close" (click)="selectedNpc = null"></button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
        <div class="npc-list">
            @for (npc of zone.Npcs; track npc) {
                <div class="npc-selector" [ngClass]="{ 'selected-npc': npc === selectedNpc }" (click)="activateNpc(npc)">
                    <img class="npc-img" [src]="npc.OutsideImagePath" />
                </div>
            }
        </div>
        <div class="row">
            @for (location of zone.AllLocations; track location) {
                <div class="mt-3 col-sm-6 mb-3 text-center" [class]="!zone.ParentZone ? 'col-lg-4 col-xxl-3' : 'col-lg-6 col-xxl-4'">
                    @if (location.LocationType === LocationType.Shop) {
                        <div
                            class="card card-hover card-bg"
                            [ngStyle]="{ 'background-image': 'url(' + $any(location).OutsideImagePath + ')' }">
                            <div class="card-body">
                                <h3 class="mb-0">
                                    <a [routerLink]="['/shop/' + $any(location).Id]" class="text-reset text-decoration-none stretched-link">
                                        {{ $any(location).OutsideName }}
                                    </a>
                                </h3>
                            </div>
                        </div>
                    }
                    @if (location.LocationType == LocationType.Zone) {
                        <div
                            class="card card-hover card-bg"
                            [ngStyle]="{ 'background-image': 'url(' + $any(location).OutsideImagePath + ')' }">
                            <div class="card-body">
                                <h3 class="mb-0">
                                    <a
                                        [routerLink]="['/explore/' + $any(location).ExternalId]"
                                        class="text-reset text-decoration-none stretched-link">
                                        {{ $any(location).OutsideName }}
                                    </a>
                                </h3>
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    </div>
}
