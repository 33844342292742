import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Shop } from 'src/app/dto';
import { AuthenticatedPageComponent } from 'src/app/components/base.component';
import { MainpageComponent } from 'src/app/components/mainpage/mainpage.component';
import { AuthService } from 'src/app/services/auth.service';
import { ShopService } from 'src/app/services/shop.service';
import { UIStateService } from 'src/app/services/ui-state.service';

@Component({
    selector: 'app-shop-finder',
    templateUrl: './shop-finder.component.html',
    styleUrls: ['./shop-finder.component.scss']
})
export class ShopFinderComponent extends AuthenticatedPageComponent implements OnInit {

    public static route: String = 'shopfinder';

    public shops: Shop[];

    constructor(private shopSvc: ShopService, private route: ActivatedRoute, authService: AuthService, router: Router, uiStateService: UIStateService) {
        super(router, authService, MainpageComponent.route, uiStateService);
    }


    ngOnInit(): void {
        this.route.params.subscribe(params => {
            this.shopSvc.getAllShops().subscribe(shops => {
                this.shops = shops;
            });
        });
    }
}
