<div class="page-header row d-flex align-items-center">
    <div class="col-sm me-auto">
        <h1>Mailbox</h1>
    </div>
</div>

<ul ngbNav #nav="ngbNav" class="nav-tabs">
    <li ngbNavItem>
        <a ngbNavLink>Conversations</a>
        <ng-template ngbNavContent>
            <button
                class="btn btn-primary btn-lg"
                id="new-conversation-toggle"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#new-conversation"
                aria-false="true"
                aria-controls="new-conversation"
                (click)="composeOpen = !composeOpen">
                <i [ngClass]="['fa', composeOpen ? 'fa-times' : 'fa-pen']" aria-hidden="true"></i>
            </button>

            <div id="new-conversation" class="accordion-collapse collapse">
                <h4 class="mb-0">Start a new conversation</h4>
                <div class="accordion-body">
                    <app-compose></app-compose>
                </div>
            </div>

            <!-- TODO make these buttons do stuff
      <div class="row mb-3">
        <div class="col me-auto">

          <button class="btn btn-light me-3">
            <i class="fa fa-check"></i> Select all
          </button>
          <button class="btn btn-light me-3">
            <i class="fa fa-trash"></i> Delete
          </button>
          <button class="btn btn-light me-3">
            <i class="fa fa-envelope-open"></i> Mark as unread
          </button>
        </div>

        <div class="col-auto">
          [pagination]
        </div>
      </div> -->

            <div class="row mb-3">
                <div class="col-12">
                    <h2>Conversations</h2>
                    <div id="mailbox-table">
                        @for (channel of channels | async; track channel) {
                            <div
                                class="mailbox-row"
                                [routerLink]="['/mailbox/conversation/' + channel.Id]"
                                [ngClass]="{ 'alerted-item': channel.ContainsNotification }">
                                @if (getMessagePreview(channel); as prev) {
                                    <div class="row">
                                        <div class="col-md-3">
                                            <strong>{{ channel.Name }}</strong>
                                        </div>
                                        <div class="col-md">
                                            <span class="message-text">{{ prev.BodyPreview }}</span>
                                        </div>
                                        <div class="col-md-auto">
                                            <span class="message-timestamp">{{ prev.DateSent | date: "shortTime" }}</span>
                                        </div>
                                    </div>
                                } @else {
                                    <div class="row">
                                        <div class="col-sm-3">
                                            <strong>{{ channel.Name }}</strong>
                                        </div>
                                        <div class="col-sm">
                                            <i>It's quiet...</i>
                                        </div>
                                        <div class="col-sm-auto">
                                            <span class="message-timestamp">{{ channel.CreateDate | date: "shortTime" }}</span>
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        </ng-template>
    </li>
    <li ngbNavItem>
        <a ngbNavLink>Buddies</a>
        <ng-template ngbNavContent>
            <div class="row">
                <div class="col-md-6 mt-3">
                    <h2>Friends</h2>
                    <div id="current-friends" class="friends-table">
                        @for (friend of friends | async; track friend) {
                            <div class="friends-items">
                                @if (friend.FriendUsername; as friendUsername) {
                                    <div class="friend-item d-flex gap-2">
                                        <img [src]="friend.Profile.Image.ImagePath" class="profile-image" />{{ friendUsername }}
                                        <div class="action-group d-flex ms-auto gap-2">
                                            <a [routerLink]="['/user/' + friendUsername]" class="btn btn-primary"
                                                ><i class="fa fa-eye" aria-hidden="true"></i>
                                            </a>
                                            <button (click)="openChat(friendUsername)" class="btn btn-primary">
                                                <img src="assets/images/icons/icon-envelope.svg" height="16px" />
                                            </button>
                                            <button class="btn btn-danger" (click)="removeFriend(friendUsername)">
                                                <i class="fa fa-user-times"></i>
                                            </button>
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>
                <div class="col-md-6 mt-3">
                    <h2>Friend Requests</h2>
                    <div id="pending-requests" class="friends-table">
                        <h3 class="head">Requests</h3>
                        @for (friend of inboundFriendRequests; track friend) {
                            <div class="friends-items">
                                @if (friend.FriendUsername; as friendUsername) {
                                    <div class="friend-item d-flex">
                                        <a [routerLink]="['/user/' + friendUsername]"
                                            ><img [src]="friend.Profile.Image.ImagePath" class="profile-image" />{{ friendUsername }}</a
                                        >
                                        <span id="date" class="ms-auto">{{ friend.SendDate | date: "shortDate" }}</span>
                                        <div class="action-group d-flex gap-2 ms-2">
                                            <button class="btn btn-success" (click)="acceptFriend(friendUsername)">
                                                <i class="fa fa-user-check"></i>
                                            </button>
                                            <button class="btn btn-danger" (click)="removeFriend(friendUsername)">
                                                <i class="fa fa-user-times"></i>
                                            </button>
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                    <div id="sent-requests" class="friends-table">
                        <h3 class="head">Sent Requests</h3>
                        @for (friend of sentFriendRequests; track friend) {
                            <div class="friends-items">
                                @if (friend.FriendUsername; as friendUsername) {
                                    <div class="friend-item d-flex">
                                        <a [routerLink]="['/user/' + friendUsername]"
                                            ><img [src]="friend.Profile.Image.ImagePath" class="profile-image" />{{ friendUsername }}</a
                                        >
                                        <span id="date" class="ms-auto">{{ friend.SendDate | date: "shortDate" }}</span>
                                        <div class="action-group d-flex ms-2 gap-2">
                                            <button class="btn btn-danger col-auto" (click)="removeFriend(friendUsername)">
                                                <i class="fa fa-ban"></i>
                                            </button>
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        </ng-template>
    </li>
</ul>
<div [ngbNavOutlet]="nav"></div>
