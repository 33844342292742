@if (npcDetails) {
    <div class="npc-details row" [ngClass]="injectedClass">
        <div [ngClass]="injectedClass == 'shop' ? 'col-md-auto text-center' : 'col-md-12 text-center'">
            <img [src]="npcDetails.ImagePath" class="zone-image" />
        </div>
        <div [ngClass]="injectedClass == 'shop' ? 'col-md' : 'col-md-12'">
            <h1>{{ npcDetails.Name }}</h1>
            <p>{{ npcDetails.Description }}</p>
        </div>
    </div>
    <app-interactor #interactor [injectedClass]="'npc'" [hidden]="!activated"></app-interactor>
}
