import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, OperatorFunction, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { AuthenticatedPageComponent } from 'src/app/components/base.component';
import { MainpageComponent } from 'src/app/components/mainpage/mainpage.component';
import { Channel, Message } from 'src/app/dto';
import { ApplicationService } from 'src/app/services/application.service';
import { AuthService } from 'src/app/services/auth.service';
import { InboxService } from 'src/app/services/inbox.service';
import { UIStateService } from 'src/app/services/ui-state.service';
import { UserService } from 'src/app/services/user.service';


@Component({
    selector: 'app-compose',
    templateUrl: './compose.component.html',
    styleUrls: ['./compose.component.scss']
})
export class ComposeComponent extends AuthenticatedPageComponent implements OnInit {

    public static route = 'mailbox/compose';

    channelName = '';
    searchUsername = '';
    message = '';


    searching = false;
    searchFailed = false;

    recipients: string[] = [];

    constructor(private inboxService: InboxService, private route: ActivatedRoute, router: Router, private coreAppService: ApplicationService) {
        super(router, coreAppService.authService, MainpageComponent.route, coreAppService.uiStateService);
        this.route.params.subscribe(params => {
            const toUsername: string = params.toUsername;
            if (toUsername && toUsername.length > 0) {
                this.recipients.push(toUsername);
            }
        });
    }

    ngOnInit(): void {
    }

    createChannel(): void {
        const channel = new Channel(null, null);
        channel.Name = this.channelName;
        channel.Usernames = this.recipients.concat(this.activeUser.Username);

        const message = new Message(null, null);
        message.MessageText = this.message;
        message.SendDate = new Date();
        this.subscribe(this.inboxService.createChannelWithInitialMessage(channel, message, true),
            () => {
                this.message = '';
                this.searchUsername = '';
                this.channelName = '';
                this.recipients.length = 0;
            }
        );


    }

    private findRecipient(recipientUsername: string) {//}: string[] {
        return this.inboxService.friendsList$.pipe(map(
            friendList => friendList.filter(fr => fr.FriendUsername.toLowerCase().includes(recipientUsername.toLowerCase()))
                .map(fr => fr.FriendUsername)
        ));
        // return this.inboxService.friendsList$
        //     .getValue()
        //     ?.filter(fr => fr.FriendUsername.toLowerCase().includes(recipientUsername.toLowerCase()))
        //     .map(fr => fr.FriendUsername);
    }

    addRecipient() {
        if (!this.searching && !this.searchFailed && this.searchUsername && this.searchUsername.length !== 0 &&
            this.recipients.indexOf(this.searchUsername) === -1) {
            if (!this.recipients) { this.recipients = []; }
            this.recipients.push(this.searchUsername);
            this.searchUsername = '';
        }
    }

    removeRecipient(recipientUsername: string) {
        this.recipients = this.recipients?.filter(r => r !== recipientUsername) ?? [];
    }

    //changing this into a normal goodboy function (param){ return val; }
    //instead of an expression body member loses 'this' context
    //because JS is still a crime against humanity.
    search: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(300),
            distinctUntilChanged(),
            tap(() => this.searching = true),
            switchMap(term =>
                this.findRecipient(term).pipe(
                    tap((searchResults) => this.searchFailed = (!searchResults || searchResults.length === 0) && term.length > 0))
            ),
            tap(() => this.searching = false));

}
